// Toolbar.js
import React, { useState, memo } from 'react';
import {
  FaTrash,
  FaTh,
  FaStream,
  FaSlidersH,
  FaLayerGroup, // Changed icon to FaLayerGroup
  FaPlus,
} from 'react-icons/fa';
import ColorPicker from './ColorPicker'; // Import the reusable ColorPicker component

const Toolbar = ({
  activeTool,
  toolSettings,
  selectTool,
  updateToolSettings,
  handleColorSelect,
  handleBackgroundColorSelect,
  handleLinesColorSelect,
  handleBackgroundTypeChange,
  handleSpacingChange,
  clearCanvas,
  backgroundSettings,
  canvasWidth, // New prop for canvas width
}) => {
  // State to toggle background settings visibility
  const [isBackgroundSettingsOpen, setIsBackgroundSettingsOpen] = useState(false);

  // State for which color picker is open: 'drawing', 'background', 'lines', or null
  const [activeColorPicker, setActiveColorPicker] = useState(null);

  // Handlers to open specific color pickers
  const openColorPicker = (pickerType) => {
    setActiveColorPicker((prev) => (prev === pickerType ? null : pickerType));
  };

  // Handlers to close color pickers
  const closeColorPicker = () => {
    setActiveColorPicker(null);
  };

  // Calculate maximum spacing based on canvas width
  const maxSpacing = canvasWidth ? canvasWidth / 2 : 300; // Default to 300 if canvasWidth is not provided

  // Function to calculate line thickness based on number of lines
  const calculateLineThickness = (numLines) => {
    // Example: Thicker lines for fewer lines, thinner for more
    // Adjust the formula as needed for desired thickness
    return Math.max(1, Math.floor(10 / (numLines || 1)));
  };

  return (
    <div className="relative z-10 flex items-center p-4 space-x-4 bg-gray-100 border-b">
      {/* Tool Selection and Stroke Width Adjuster */}
      <div className="flex items-center space-x-2">
        {/* Pen Tool */}
        <button
          onClick={() => selectTool('pen')}
          className={`flex items-center justify-center border rounded ${
            activeTool === 'pen' ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'
          }`}
          style={{ width: '50px', height: '50px' }}
          aria-label="Select Pen Tool"
        >
          {/* Pen Icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="currentColor"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
          </svg>
        </button>

        {/* Highlighter Tool */}
        <button
          onClick={() => selectTool('highlighter')}
          className={`flex items-center justify-center border rounded ${
            activeTool === 'highlighter' ? 'bg-yellow-300' : 'bg-white text-gray-700'
          }`}
          style={{ width: '50px', height: '50px' }}
          aria-label="Select Highlighter Tool"
        >
          {/* Highlighter Icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="currentColor"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M3 21v-2.81l14.19-14.19 2.81 2.81L5.81 21H3zm2-2h12.19L19 13.19 13.19 7.38 5 15.56V19H5z" />
          </svg>
        </button>

        {/* Eraser Tool */}
        <button
          onClick={() => selectTool('eraser')}
          className={`flex items-center justify-center border rounded ${
            activeTool === 'eraser' ? 'bg-red-500 text-white' : 'bg-white text-gray-700'
          }`}
          style={{ width: '50px', height: '50px' }}
          aria-label="Select Eraser Tool"
        >
          {/* Eraser Icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="currentColor"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M16.24 15.24l-8.49-8.49 1.41-1.41 8.49 8.49-1.41 1.41zM19 6.41L17.59 5 14 8.59 15.41 10 19 6.41zM5 20c-.55 0-1-.45-1-1V6.41l4.17-4.17 2.83 2.83L5 20z" />
          </svg>
        </button>

        {/* Background Settings Toggle */}
        <div className="relative">
          <button
            onClick={() => setIsBackgroundSettingsOpen(!isBackgroundSettingsOpen)}
            className="flex items-center justify-center text-gray-700 bg-white border rounded-full"
            style={{ width: '50px', height: '50px' }}
            aria-label="Toggle Background Settings"
          >
            <FaLayerGroup size={20} /> {/* Changed Icon */}
          </button>

          {/* Background Settings Panel */}
          {isBackgroundSettingsOpen && (
            <div className="absolute left-0 z-20 flex flex-col p-4 mt-2 space-y-4 bg-white border rounded shadow-lg top-full">
              {/* Background Type Selection */}
              <div className="flex items-center space-x-2">
                {/* Blank Background */}
                <button
                  onClick={() => handleBackgroundTypeChange('blank')}
                  className={`flex items-center justify-center w-10 h-10 border rounded-full ${
                    backgroundSettings.type === 'blank' ? 'bg-blue-500' : 'bg-white'
                  }`}
                  aria-label="Select Blank Background"
                >
                  <FaTh size={16} className={backgroundSettings.type === 'blank' ? 'text-white' : 'text-gray-700'} />
                </button>

                {/* Lines Background */}
                <button
                  onClick={() => handleBackgroundTypeChange('lines')}
                  className={`flex items-center justify-center w-10 h-10 border rounded-full ${
                    backgroundSettings.type === 'lines' ? 'bg-blue-500' : 'bg-white'
                  }`}
                  aria-label="Select Lines Background"
                >
                  <FaStream size={16} className={backgroundSettings.type === 'lines' ? 'text-white' : 'text-gray-700'} />
                </button>

                {/* Grid Background */}
                <button
                  onClick={() => handleBackgroundTypeChange('grid')}
                  className={`flex items-center justify-center w-10 h-10 border rounded-full ${
                    backgroundSettings.type === 'grid' ? 'bg-blue-500' : 'bg-white'
                  }`}
                  aria-label="Select Grid Background"
                >
                  <FaTh size={16} className={backgroundSettings.type === 'grid' ? 'text-white' : 'text-gray-700'} />
                </button>

                {/* Cross Background */}
                <button
                  onClick={() => handleBackgroundTypeChange('cross')}
                  className={`flex items-center justify-center w-10 h-10 border rounded-full ${
                    backgroundSettings.type === 'cross' ? 'bg-blue-500' : 'bg-white'
                  }`}
                  aria-label="Select Cross Background"
                >
                  <FaPlus size={16} className={backgroundSettings.type === 'cross' ? 'text-white' : 'text-gray-700'} />
                </button>
              </div>

              {/* Background and Lines/Grid Color Selectors */}
              <div className="flex space-x-4">
                {/* Background Color Selector */}
                <div className="relative">
                  <button
                    onClick={() => openColorPicker('background')}
                    className="flex items-center justify-center w-10 h-10 border rounded-full cursor-pointer"
                    style={{ backgroundColor: backgroundSettings.color }}
                    aria-label="Select Background Color"
                  >
                    {/* No Icon needed; color displayed via background */}
                  </button>

                  {/* Background Color Picker */}
                  {activeColorPicker === 'background' && (
                    <ColorPicker
                      color={backgroundSettings.color}
                      onChangeComplete={handleBackgroundColorSelect}
                      closePicker={closeColorPicker}
                    />
                  )}
                </div>

                {/* Lines/Grid Color Selector */}
                <div className="relative">
                  <button
                    onClick={() => openColorPicker('lines')}
                    className="flex items-center justify-center w-10 h-10 border rounded-full cursor-pointer"
                    style={{ backgroundColor: backgroundSettings.lineColor }}
                    aria-label="Select Lines/Grid Color"
                  >
                    {/* No Icon needed; color displayed via background */}
                  </button>

                  {/* Lines/Grid Color Picker */}
                  {activeColorPicker === 'lines' && (
                    <ColorPicker
                      color={backgroundSettings.lineColor}
                      onChangeComplete={handleLinesColorSelect}
                      closePicker={closeColorPicker}
                    />
                  )}
                </div>
              </div>

              {/* Conditional Rendering for Cross Background Sliders */}
              {backgroundSettings.type === 'cross' && (
                <div className="flex flex-col space-y-4">
                  {/* Vertical Lines Slider */}
                  <div className="flex items-center space-x-2">
                    <FaSlidersH className="text-gray-700" size={20} aria-label="Vertical Lines Slider" />
                    <input
                      type="range"
                      min="1"
                      max="20"
                      value={backgroundSettings.verticalLines || 1}
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        handleSpacingChange('vertical', value, calculateLineThickness(value));
                      }}
                      className="w-32 h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                      aria-label="Adjust Number of Vertical Lines"
                    />
                  </div>

                  {/* Horizontal Lines Slider */}
                  <div className="flex items-center space-x-2">
                    <FaSlidersH className="text-gray-700" size={20} aria-label="Horizontal Lines Slider" />
                    <input
                      type="range"
                      min="1"
                      max="20"
                      value={backgroundSettings.horizontalLines || 1}
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        handleSpacingChange('horizontal', value, calculateLineThickness(value));
                      }}
                      className="w-32 h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                      aria-label="Adjust Number of Horizontal Lines"
                    />
                  </div>
                </div>
              )}

              {/* Line/Grid Spacing Selector for Other Background Types */}
              {backgroundSettings.type !== 'cross' && (
                <div className="flex items-center space-x-2">
                  <FaSlidersH className="text-gray-700" size={20} aria-label="Line/Grid Spacing Slider" />
                  <input
                    type="range"
                    min="20"
                    max={maxSpacing} // Dynamic max based on canvas width
                    value={backgroundSettings.spacing}
                    onChange={(e) => {
                      const spacing = parseInt(e.target.value);
                      const lineThickness = calculateLineThickness(spacing);
                      handleSpacingChange('spacing', spacing, lineThickness);
                    }}
                    className="w-32 h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                    aria-label="Adjust Line/Grid Spacing"
                  />
                </div>
              )}
            </div>
          )}
        </div>

        {/* Drawing Color Picker */}
        {(activeTool === 'pen' || activeTool === 'highlighter') && (
          <div className="relative">
            <button
              onClick={() => openColorPicker('drawing')}
              className="flex items-center justify-center w-10 h-10 border rounded-full cursor-pointer"
              style={{ backgroundColor: toolSettings[activeTool].strokeColor }}
              aria-label="Open Drawing Color Picker"
            >
              {/* No Icon needed; color displayed via background */}
            </button>

            {/* Drawing Color Picker */}
            {activeColorPicker === 'drawing' && (
              <ColorPicker
                color={toolSettings[activeTool].strokeColor}
                onChangeComplete={(color) => handleColorSelect(color)}
                closePicker={closeColorPicker}
              />
            )}
          </div>
        )}

        {/* Stroke Width Adjuster */}
        <div className="flex items-center space-x-2">
          <FaSlidersH className="text-gray-700" size={20} aria-label="Stroke Width Slider" />
          <input
            type="range"
            min="1"
            max="100" // Increased maximum value for larger spacing
            value={toolSettings[activeTool].strokeWidth}
            onChange={(e) =>
              updateToolSettings(activeTool, {
                strokeWidth: parseInt(e.target.value) || 1,
              })
            }
            className="w-32 h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
            aria-label="Adjust Stroke Width"
          />
        </div>
      </div>

      {/* Spacer */}
      <div className="flex-grow"></div>

      {/* Clear Button */}
      <button
        onClick={clearCanvas}
        className="flex items-center justify-center p-3 ml-auto text-white bg-red-500 rounded hover:bg-red-600 focus:outline-none"
        style={{ width: '50px', height: '50px' }}
        aria-label="Clear Whiteboard"
      >
        <FaTrash size={20} />
      </button>
    </div>
  );
};

// Export the memoized component for performance optimization
export default memo(Toolbar);
