// src/components/Teachers/TeacherWorkbook.js

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { FaEye, FaChalkboardTeacher, FaTimes, FaChevronLeft, FaChevronRight, FaThLarge, FaList, FaSave } from 'react-icons/fa';
import { db } from '../../firebase';
import { collection, getDocs, query, doc, setDoc, getDoc } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';
import PropTypes from 'prop-types';
import Button from '../ui/Button';
import Card from '../ui/Card';

const TeacherWorkbook = ({ onClose, classID }) => {
  const { currentUser } = useAuth();
  const [mode, setMode] = useState(null); // null, 'view', or 'demonstrate'
  const [studentWorkbooks, setStudentWorkbooks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [viewMode, setViewMode] = useState('grid');
  const [currentIndex, setCurrentIndex] = useState(0);

  // States for teacher's example workbook
  const [exampleContent, setExampleContent] = useState('');
  const [isSavingExample, setIsSavingExample] = useState(false);
  const [saveExampleMessage, setSaveExampleMessage] = useState('');

  // Memoize the workbook collection reference
  const workbookCollectionRef = useMemo(() => {
    if (!classID) return null;
    return collection(db, 'activeclasses', classID, 'workbook');
  }, [classID]);

  // Function to fetch the teacher's example workbook
  const fetchExampleWorkbook = useCallback(async () => {
    if (!workbookCollectionRef) {
      console.error('Cannot fetch example workbook. Missing classID.');
      return;
    }

    try {
      const exampleDocRef = doc(workbookCollectionRef, 'work-example');
      const docSnap = await getDoc(exampleDocRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setExampleContent(data.workbook || '');
      } else {
        setExampleContent('');
      }
    } catch (err) {
      console.error('Error fetching example workbook:', err);
      setError('Failed to load example workbook. Please try again.');
    }
  }, [workbookCollectionRef]);

  // Fetch all student workbooks
  const fetchStudentWorkbooks = useCallback(async () => {
    if (!workbookCollectionRef || !currentUser?.uid) {
      console.error('Cannot fetch workbooks. Missing classID or currentUser.uid');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const q = query(workbookCollectionRef);
      const querySnapshot = await getDocs(q);

      const workbooks = querySnapshot.docs
        .filter(doc => doc.id !== 'classInfo' && doc.id !== 'work-example') // Exclude 'work-example'
        .map(doc => ({ id: doc.id, ...doc.data() }));

      setStudentWorkbooks(workbooks);
      setMode('view');
    } catch (err) {
      console.error('Error fetching student workbooks:', err);
      setError('Failed to load student workbooks. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [workbookCollectionRef, currentUser?.uid]);

  const handleViewWorkbooks = useCallback(() => {
    fetchStudentWorkbooks();
  }, [fetchStudentWorkbooks]);

  const handleDemonstrate = useCallback(() => {
    setMode('demonstrate');
    fetchExampleWorkbook(); // Now safely defined before usage
  }, [fetchExampleWorkbook]);

  const handleBack = useCallback(() => {
    setMode(null);
  }, []);

  const handlePrevious = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : studentWorkbooks.length - 1
    );
  }, [studentWorkbooks.length]);

  const handleNext = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex < studentWorkbooks.length - 1 ? prevIndex + 1 : 0
    );
  }, [studentWorkbooks.length]);

  const renderWorkbookContent = useCallback((workbook) => (
    <Card key={workbook.id} className="p-4">
      <h4 className="mb-2 text-lg font-semibold">{workbook.name || 'Unnamed Student'}</h4>
      <textarea
        className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500 min-h-[200px] bg-gray-50"
        value={workbook.workbook}
        readOnly
      ></textarea>
    </Card>
  ), []);

  const memoizedWorkbookContent = useMemo(() => {
    if (viewMode === 'grid') {
      return (
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
          {studentWorkbooks.map(renderWorkbookContent)}
        </div>
      );
    } else {
      return studentWorkbooks.length > 0 ? (
        <div className="relative">
          {renderWorkbookContent(studentWorkbooks[currentIndex])}
          <div className="flex justify-between mt-4">
            <Button onClick={handlePrevious} className="flex items-center">
              <FaChevronLeft className="mr-2" /> Previous
            </Button>
            <Button onClick={handleNext} className="flex items-center">
              Next <FaChevronRight className="ml-2" />
            </Button>
          </div>
        </div>
      ) : null;
    }
  }, [viewMode, studentWorkbooks, currentIndex, renderWorkbookContent, handlePrevious, handleNext]);

  // Function to handle saving the example workbook
  const handleSaveExample = useCallback(async () => {
    if (!workbookCollectionRef) {
      console.error('Cannot save example workbook. Missing classID.');
      return;
    }

    setIsSavingExample(true);
    setSaveExampleMessage('');

    try {
      const exampleDocRef = doc(workbookCollectionRef, 'work-example');
      const workbookData = {
        name: 'Work Example',
        workbook: exampleContent,
        updatedAt: new Date(),
      };

      await setDoc(exampleDocRef, workbookData, { merge: true });
      setSaveExampleMessage('Example workbook saved successfully!');
    } catch (err) {
      console.error('Error saving example workbook:', err);
      setSaveExampleMessage('Failed to save example workbook. Please try again.');
    } finally {
      setIsSavingExample(false);
      setTimeout(() => setSaveExampleMessage(''), 3000);
    }
  }, [workbookCollectionRef, exampleContent]);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-50">
      <div className="relative w-full max-w-6xl p-6 overflow-y-auto bg-white rounded-lg shadow-lg">
        <button
          onClick={onClose}
          className="absolute text-2xl text-gray-500 top-4 right-4 hover:text-gray-700"
          aria-label="Close"
        >
          <FaTimes />
        </button>

        <div className="mb-6 text-center">
          <h2 className="text-2xl font-semibold">Teacher Workbook</h2>
        </div>

        {mode === null && (
          <div className="flex justify-center space-x-8">
            <Button
              onClick={handleViewWorkbooks}
              className="flex flex-col items-center px-6 py-4 text-white bg-blue-500 rounded-lg shadow hover:bg-blue-600"
            >
              <FaEye className="mb-2 text-3xl" />
              View Student Workbooks
            </Button>
            <Button
              onClick={handleDemonstrate}
              className="flex flex-col items-center px-6 py-4 text-white bg-green-500 rounded-lg shadow hover:bg-green-600"
            >
              <FaChalkboardTeacher className="mb-2 text-3xl" />
              Demonstrate Example
            </Button>
          </div>
        )}

        {mode === 'view' && (
          <>
            <div className="flex items-center justify-between mb-6">
              <h3 className="text-xl font-semibold">Student Workbooks</h3>
              <div className="flex space-x-2">
                <Button
                  onClick={() => setViewMode('grid')}
                  className={`flex items-center px-3 py-2 ${
                    viewMode === 'grid' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
                  } rounded`}
                >
                  <FaThLarge className="mr-2" /> Grid
                </Button>
                <Button
                  onClick={() => setViewMode('single')}
                  className={`flex items-center px-3 py-2 ${
                    viewMode === 'single' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
                  } rounded`}
                >
                  <FaList className="mr-2" /> Single
                </Button>
              </div>
            </div>

            {loading && <p>Loading...</p>}
            {error && <p className="text-red-500">{error}</p>}

            {!loading && !error && studentWorkbooks.length === 0 && (
              <p>No student workbooks found.</p>
            )}

            {memoizedWorkbookContent}
          </>
        )}

        {mode === 'demonstrate' && (
          <div>
            <div className="mb-4">
              <h3 className="text-xl font-semibold">Example Workbook</h3>
            </div>
            <div className="mb-4">
              <textarea
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500 min-h-[300px] bg-gray-50"
                placeholder="Write your example workbook content here..."
                value={exampleContent}
                onChange={(e) => setExampleContent(e.target.value)}
              ></textarea>
            </div>
            {/* Save Example Button */}
            <div className="flex justify-between mb-4">
              <Button
                onClick={handleSaveExample}
                className={`flex items-center px-4 py-2 text-white bg-green-500 rounded hover:bg-green-600 ${
                  isSavingExample ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                title="Save Example Workbook"
                disabled={isSavingExample}
              >
                <FaSave className="mr-2" />
                {isSavingExample ? 'Saving...' : 'Save Example'}
              </Button>
              {/* Feedback Message */}
              {saveExampleMessage && (
                <div className="text-sm text-center text-green-600">
                  {saveExampleMessage}
                </div>
              )}
            </div>
          </div>
        )}

        {mode !== null && (
          <div className="mt-6">
            <Button onClick={handleBack} className="text-gray-600 hover:text-gray-800">
              Back to Main Menu
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

// PropTypes for validation
TeacherWorkbook.propTypes = {
  onClose: PropTypes.func.isRequired,
  classID: PropTypes.string.isRequired,
};

export default TeacherWorkbook;
