import { parseAsync } from 'pptx-parser';

export const readPPTXFile = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const arrayBuffer = e.target.result;
        const pptx = await parseAsync(arrayBuffer);
        const slides = pptx.slides.map((slide, index) => {
          const content = parseSlideContent(slide);
          return {
            id: index + 1,
            subtitle: slide.title || '',
            content: content,
          };
        });

        const slideshowData = {
          title: pptx.title || 'Imported Slideshow',
          description: pptx.description || '',
          tags: pptx.tags || [],
          slides: slides,
        };

        resolve(slideshowData);
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
};

const parseSlideContent = (slide) => {
  // This is a simplified parser. You may need to expand this based on your needs.
  if (slide.images && slide.images.length > 0) {
    return {
      type: 'image',
      src: slide.images[0].src, // You might need to handle image data differently
    };
  } else if (slide.text && slide.text.length > 0) {
    return {
      type: 'fact',
      text: slide.text.join('\n'),
    };
  }
  // Add more conditions as needed for different slide types
  return null;
};