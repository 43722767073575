// ColorPicker.js
import React, { useEffect, useRef } from 'react';
import { ChromePicker } from 'react-color';

const ColorPicker = ({ color, onChangeComplete, closePicker }) => {
  const pickerRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        closePicker();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closePicker]);

  return (
    <div ref={pickerRef} className="absolute z-30 p-2 bg-white border rounded shadow-lg top-12">
      <ChromePicker
        color={color}
        onChangeComplete={(colorResult) => onChangeComplete(colorResult.hex)}
        disableAlpha
      />
      <button
        onClick={closePicker}
        className="px-3 py-1 mt-2 text-sm text-white bg-gray-700 rounded hover:bg-gray-800 focus:outline-none"
        aria-label="Close Color Picker"
      >
        Close
      </button>
    </div>
  );
};

export default ColorPicker;
