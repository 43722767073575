import React, { useState } from 'react';
import Button from '../ui/Button';
import Card from '../ui/Card';
import SlideshowLibrary from './SlideshowLibrary';
import SlideshowCreationTool from './SlideshowCreationTool';
import SaveSlideshowModal from './Modal/SaveSlideshowModal';
import ImportSlideshowModal from './Modal/ImportSlideshowModal';
import { db, auth } from '../../firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';

const SlideshowApp = ({ onClose, classId }) => {
  const [mode, setMode] = useState(null); // 'create', 'library', or null
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [creationData, setCreationData] = useState(null);

  const handleSaveSlideshow = async (title, description, tags, slides) => {
    try {
      await addDoc(collection(db, 'slideshows'), {
        title,
        description,
        tags,
        slides,
        createdBy: auth.currentUser.uid,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        isPublic: false,
      });
      alert('Slideshow saved to library!');
      setShowSaveModal(false);
    } catch (error) {
      console.error('Error saving slideshow:', error);
      alert('Failed to save slideshow.');
    }
  };

  const handleImportSlideshow = (slideshow) => {
    setCreationData(slideshow);
    setMode('create');
    setShowImportModal(false);
  };

  const ButtonStyle = "w-40 h-40 text-xl font-bold rounded-lg shadow-lg transition-transform hover:scale-105 mx-4";

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-11/12 overflow-auto bg-white rounded-lg shadow-lg h-5/6">
        <header className="relative flex items-center justify-center p-4 border-b">
          <h2 className="text-2xl font-bold">Slideshow Manager</h2>
          <Button onClick={onClose} variant="ghost" className="absolute right-4">
            Close
          </Button>
        </header>

        <div className="p-4">
          {mode === null ? (
            <div className="flex flex-row items-center justify-center h-full space-x-8">
              <Button
                onClick={() => setMode('create')}
                className={`${ButtonStyle} bg-blue-500 text-white`}
              >
                Create
              </Button>
              <Button
                onClick={() => setMode('library')}
                className={`${ButtonStyle} bg-green-500 text-white`}
              >
                Library
              </Button>
              <Button
                onClick={() => setShowImportModal(true)}
                className={`${ButtonStyle} bg-yellow-500 text-white`}
              >
                Import
              </Button>
            </div>
          ) : (
            <>
              
                
              
              {mode === 'create' ? (
                <div>
                  <SlideshowCreationTool initialData={creationData} />
                  <div className="flex justify-end mt-4">
                    <Button onClick={() => setShowSaveModal(true)}>
                      Save to Library
                    </Button>
                  </div>
                </div>
              ) : (
                <SlideshowLibrary onImport={handleImportSlideshow} />
              )}
            </>
          )}
        </div>
      </div>

      {showSaveModal && (
        <SaveSlideshowModal
          onClose={() => setShowSaveModal(false)}
          onSave={handleSaveSlideshow}
        />
      )}

      {showImportModal && (
        <ImportSlideshowModal
          onClose={() => setShowImportModal(false)}
          onImport={handleImportSlideshow}
        />
      )}
    </div>
  );
};

export default SlideshowApp;