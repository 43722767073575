import React, { useState, useEffect } from 'react';
import { Lock, Unlock, LogOut } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import {
  collection,
  onSnapshot,
  doc,
  writeBatch,
  getDocs,
} from 'firebase/firestore';
import { signOut } from 'firebase/auth';
import { useAuth } from '../../contexts/AuthContext';
import { db, auth, serverTimestamp } from '../../firebase';

const Sidebar = ({
  isOpen,
  toggleSidebar,
  username,
  handleStartApp,
  selectedClasses,
  setSelectedClasses,
  onManageClasses,
  classes,
  setClasses,
}) => {
  const navigate = useNavigate();
  const { currentUser, userRole } = useAuth();
  const [isLocked, setIsLocked] = useState(false);
  const [selectedApps, setSelectedApps] = useState(new Set());
  const [availableModules] = useState([
    { name: 'Digital Workbook', color: 'bg-purple-500' },
    { name: 'Slideshow', color: 'bg-green-500' },
    { name: 'Assessment', color: 'bg-pink-500' },
    { name: 'Whiteboard', color: 'bg-red-500' },
    { name: 'Book Quest', color: 'bg-teal-500' },
    { name: 'Games', color: 'bg-blue-500' },
  ]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const [showClassConfirm, setShowClassConfirm] = useState(false); // Confirmation for class toggle
  const [classToToggle, setClassToToggle] = useState(null); // Class ID to toggle
  const [isLoading, setIsLoading] = useState(true);

  // Initialize selectedClasses to an empty Set when Sidebar mounts
  useEffect(() => {
    setSelectedClasses(new Set());
  }, [setSelectedClasses]);

  // Helper function to generate class codes
  const generateClassCode = (length) => {
    const characters = 'ACEFGHJKMNSTUVWXY';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  // Fetch Classes from Firestore
  useEffect(() => {
    if (currentUser && userRole === 'teacher') {
      setIsLoading(true);
      const userClassesRef = collection(db, `users/${currentUser.uid}/classes`);
      const unsubscribe = onSnapshot(
        userClassesRef,
        (snapshot) => {
          const fetchedClasses = snapshot.docs.map((docSnap) => ({
            id: docSnap.id,
            ...docSnap.data(),
          }));
          setClasses(fetchedClasses);
          setIsLoading(false);
        },
        (error) => {
          console.error('Error fetching classes:', error);
          setIsLoading(false);
        }
      );

      return () => unsubscribe();
    }
  }, [currentUser, userRole, setClasses]);

  // Synchronize isLocked state with selected classes' modes
  useEffect(() => {
    if (selectedClasses.size === 0) {
      setIsLocked(false);
      return;
    }

    // Gather modes of all selected classes
    const selectedModes = Array.from(selectedClasses)
      .map((classId) => classes.find((cls) => cls.id === classId)?.mode)
      .filter((mode) => mode != null);

    if (selectedModes.length === 0) {
      setIsLocked(false);
      return;
    }

    const allLocked = selectedModes.every((mode) => mode === 'locked');
    const allUnlocked = selectedModes.every((mode) => mode === 'unlocked');

    if (allLocked) {
      setIsLocked(true);
    } else if (allUnlocked) {
      setIsLocked(false);
    } else {
      // Mixed modes: Default to unlocked
      setIsLocked(false);
    }
  }, [classes, selectedClasses]);

  // Toggle Lock/Unlock for Selected Classes
  const toggleLock = async () => {
    if (selectedClasses.size > 0) {
      setIsLocked(!isLocked);
      setIsUpdating(true);

      try {
        const batch = writeBatch(db);
        selectedClasses.forEach((classId) => {
          const classDoc = classes.find((cls) => cls.id === classId);
          if (classDoc?.classStatus !== 'active') return;

          const classDocRef = doc(db, `users/${currentUser.uid}/classes`, classId);
          const activeClassDocRef = doc(db, 'activeclasses', classId);

          // Update mode based on the new isLocked state
          const newMode = !isLocked ? 'locked' : 'unlocked';
          batch.update(classDocRef, { mode: newMode });
          batch.update(activeClassDocRef, { mode: newMode });

          if (!isLocked) {
            // If locking, clear currentApp and accessibleModules
            batch.update(classDocRef, { currentApp: null, accessibleModules: [] });
            batch.update(activeClassDocRef, { currentApp: null, accessibleModules: [] });
          }
        });
        await batch.commit();
        setSelectedApps(new Set());
      } catch (error) {
        console.error('Error toggling lock:', error);
        alert('Failed to toggle lock status. Please try again.');
      } finally {
        setIsUpdating(false);
      }
    }
  };

  // Initiate Toggle Class with Confirmation
  const initiateToggleClass = (classId) => {
    setClassToToggle(classId);
    setShowClassConfirm(true);
  };

  // Confirm Toggle Class
  const confirmToggleClass = async () => {
    if (!classToToggle) return;

    // Close the confirmation popup immediately
    setShowClassConfirm(false);

    const cls = classes.find((c) => c.id === classToToggle);
    if (!cls) {
      setClassToToggle(null);
      return;
    }

    const activeClass = classes.find((c) => c.classStatus === 'active' && c.id !== classToToggle);

    if (cls.classStatus === 'inactive' && activeClass) {
      // Activating a new class while another is active
      try {
        setIsUpdating(true);
        const batch = writeBatch(db);

        // Deactivate the currently active class
        const activeClassDocRef = doc(db, `users/${currentUser.uid}/classes`, activeClass.id);
        const activeActiveClassDocRef = doc(db, 'activeclasses', activeClass.id);
        const activeClassCodeDocRef = doc(db, 'classcodes', activeClass.id);

        batch.update(activeClassDocRef, {
          classStatus: 'inactive',
          mode: 'unlocked',
          currentApp: null,
          signedInStudents: [],
          'class code': generateClassCode(16),
          accessibleModules: [],
        });
        batch.delete(activeActiveClassDocRef);
        batch.delete(activeClassCodeDocRef);

        // Activate the new class
        const classDocRef = doc(db, `users/${currentUser.uid}/classes`, cls.id);
        const activeClassesRef = collection(db, 'activeclasses');
        const activeClassDocNewRef = doc(activeClassesRef, cls.id);
        const classCodesRef = collection(db, 'classcodes');
        const classCodeDocRef = doc(classCodesRef, cls.id);

        setSelectedClasses(new Set([cls.id]));
        const newClassCode = generateClassCode(6);

        // Fetch student names from students collection
        const studentsRef = collection(
          db,
          `users/${currentUser.uid}/classes/${cls.id}/students`
        );
        const studentsSnapshot = await getDocs(studentsRef);
        const studentNames = studentsSnapshot.docs.map((docSnap) => docSnap.data().name);

        if (studentNames.length === 0) {
          throw new Error('No students found in this class.');
        }

        batch.update(classDocRef, {
          classStatus: 'active',
          'class code': newClassCode,
          mode: 'unlocked',
          currentApp: null,
          accessibleModules: [],
        });
        batch.set(activeClassDocNewRef, {
          classcode: newClassCode,
          students: studentNames,
          signinstudents: [],
          teacherID: currentUser.uid,
          mode: 'unlocked',
          accessibleModules: [],
          currentApp: null,
        });
        batch.set(classCodeDocRef, {
          classcode: newClassCode,
        });

        await batch.commit();
        setSelectedApps(new Set());
      } catch (error) {
        console.error('Error toggling class:', error);
        alert('Failed to update class status.');
      } finally {
        setIsUpdating(false);
        setClassToToggle(null);
      }
    } else {
      // Simply toggle the class without needing to deactivate another
      await toggleClass(classToToggle);
      setClassToToggle(null);
    }
  };

  // Toggle Class Active/Inactive
  const toggleClass = async (classId) => {
    const cls = classes.find((c) => c.id === classId);
    if (!cls) return;

    const newSelection = new Set(selectedClasses);

    try {
      setIsUpdating(true);
      const classDocRef = doc(db, `users/${currentUser.uid}/classes`, classId);
      const activeClassesRef = collection(db, 'activeclasses');
      const activeClassDocRef = doc(activeClassesRef, classId);
      const classCodesRef = collection(db, 'classcodes');
      const classCodeDocRef = doc(classCodesRef, classId);

      const batch = writeBatch(db);

      if (cls.classStatus === 'active') {
        // Deactivating the class
        newSelection.delete(classId);
        batch.update(classDocRef, {
          classStatus: 'inactive',
          mode: 'unlocked',
          currentApp: null,
          signedInStudents: [],
          'class code': generateClassCode(16),
          accessibleModules: [],
        });
        batch.delete(activeClassDocRef);
        batch.delete(classCodeDocRef);
      } else {
        // Activating the class
        newSelection.add(classId);
        const newClassCode = generateClassCode(6);

        // Fetch student names from students collection
        const studentsRef = collection(
          db,
          `users/${currentUser.uid}/classes/${classId}/students`
        );
        const studentsSnapshot = await getDocs(studentsRef);
        const studentNames = studentsSnapshot.docs.map((docSnap) => docSnap.data().name);

        if (studentNames.length === 0) {
          throw new Error('No students found in this class.');
        }

        // Set mode to 'unlocked' when activating
        batch.update(classDocRef, {
          classStatus: 'active',
          'class code': newClassCode,
          mode: 'unlocked',
          currentApp: null,
          accessibleModules: [],
        });
        batch.set(activeClassDocRef, {
          classcode: newClassCode,
          students: studentNames,
          signinstudents: [],
          teacherID: currentUser.uid,
          mode: 'unlocked',
          accessibleModules: [],
          currentApp: null,
        });
        batch.set(classCodeDocRef, {
          classcode: newClassCode,
        });
      }

      await batch.commit();
      setSelectedClasses(newSelection);
      setSelectedApps(new Set());
    } catch (error) {
      console.error('Error toggling class:', error);
      alert('Failed to update class status.');
    } finally {
      setIsUpdating(false);
    }
  };

  // Toggle App Activation/Deactivation
  const toggleApp = async (appName) => {
    if (selectedClasses.size > 0) {
      setIsUpdating(true);
      try {
        const batch = writeBatch(db);

        selectedClasses.forEach((classId) => {
          const classData = classes.find((cls) => cls.id === classId);
          if (!classData || classData.classStatus !== 'active') return;

          const classDocRef = doc(db, `users/${currentUser.uid}/classes`, classId);
          const activeClassDocRef = doc(db, 'activeclasses', classId);

          if (classData.mode === 'locked') {
            // If locked, toggle currentApp
            batch.update(classDocRef, {
              currentApp: classData.currentApp === appName ? null : appName,
            });
            batch.update(activeClassDocRef, {
              currentApp: classData.currentApp === appName ? null : appName,
            });
          } else {
            // If unlocked, toggle accessibleModules
            const accessibleModules = classData.accessibleModules || [];
            const newAccessibleModules = accessibleModules.includes(appName)
              ? accessibleModules.filter((mod) => mod !== appName)
              : [...accessibleModules, appName];
            batch.update(classDocRef, { accessibleModules: newAccessibleModules });
            batch.update(activeClassDocRef, { accessibleModules: newAccessibleModules });
          }
        });

        await batch.commit();

        // Update local state
        const allLocked = Array.from(selectedClasses).every(
          (classId) => classes.find((c) => c.id === classId)?.mode === 'locked'
        );

        if (allLocked) {
          const firstClass = classes.find((c) => c.id === Array.from(selectedClasses)[0]);
          setSelectedApps(new Set(firstClass?.currentApp ? [firstClass.currentApp] : []));
        } else {
          const activeApps = new Set();
          selectedClasses.forEach((classId) => {
            const cls = classes.find((c) => c.id === classId);
            if (cls && cls.accessibleModules) {
              cls.accessibleModules.forEach((app) => activeApps.add(app));
            }
          });
          setSelectedApps(activeApps);
        }
      } catch (error) {
        console.error('Error toggling app:', error);
        alert('Failed to toggle app. Please try again.');
      } finally {
        setIsUpdating(false);
      }
    }
  };

  const isAppsActive = selectedClasses.size > 0;

  // Handle Logout: Deactivate all active classes and clean up
  const handleLogout = async () => {
    try {
      setIsUpdating(true);

      // Filter only active classes
      const activeClasses = classes.filter((cls) => cls.classStatus === 'active');

      if (activeClasses.length > 0) {
        const batch = writeBatch(db);

        for (const cls of activeClasses) {
          const classDocRef = doc(db, `users/${currentUser.uid}/classes`, cls.id);
          const activeClassDocRef = doc(db, 'activeclasses', cls.id);
          const classCodeDocRef = doc(db, 'classcodes', cls.id);

          batch.update(classDocRef, {
            classStatus: 'inactive',
            mode: 'unlocked',
            currentApp: null,
            signedInStudents: [],
            'class code': generateClassCode(16),
            accessibleModules: [],
          });
          batch.delete(activeClassDocRef);
          batch.delete(classCodeDocRef);
        }

        await batch.commit();
      }

      // Now sign out
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error during logout:', error);
      alert('Failed to logout properly.');
    } finally {
      setIsUpdating(false);
    }
  };

  // Function to check if an app is active for selected classes
  const isAppActive = (appName) => {
    if (isLocked) {
      return Array.from(selectedClasses).some(
        (classId) => classes.find((c) => c.id === classId)?.currentApp === appName
      );
    } else {
      return Array.from(selectedClasses).some(
        (classId) => classes.find((c) => c.id === classId)?.accessibleModules?.includes(appName)
      );
    }
  };

  return (
    <>
      <div
        className={`fixed left-0 top-0 h-full bg-white shadow-lg transition-all duration-300 ease-in-out ${
          isOpen ? 'w-64' : 'w-0'
        } overflow-hidden flex flex-col z-50`}
      >
        <div className="flex-grow p-4 space-y-4 overflow-y-auto">
          {/* Class Selection Section */}
          <div className="p-3 border rounded">
            <button
              onClick={onManageClasses}
              className="w-full px-3 py-2 mb-2 text-sm text-white bg-blue-500 rounded hover:bg-blue-600"
              disabled={isUpdating || isLoading}
            >
              Manage Classes
            </button>
            <div className="my-2 border-t border-gray-300"></div>
            {isLoading ? (
              <div className="text-center text-gray-500">Loading classes...</div>
            ) : (
              classes.map((cls) => (
                <button
                  key={cls.id}
                  onClick={() => initiateToggleClass(cls.id)} // Updated onClick to initiate confirmation
                  className={`w-full py-2 px-3 rounded mb-2 text-sm transition-colors truncate ${
                    cls.classStatus === 'active'
                      ? 'bg-orange-500 text-white hover:bg-orange-600'
                      : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                  }`}
                  disabled={isUpdating}
                >
                  {cls.name}
                </button>
              ))
            )}
          </div>

          {/* Select Slideshow Button */}
          <div
            className={`p-3 border rounded transition-colors ${
              isAppsActive ? 'bg-white' : 'bg-gray-100'
            }`}
          >
            <button
              onClick={() => handleStartApp(null, 'Slideshow')}
              className={`w-full px-3 py-2 text-sm rounded transition-colors truncate ${
                isAppsActive
                  ? 'bg-green-500 text-white hover:bg-green-600'
                  : 'bg-gray-300 text-gray-500 cursor-not-allowed'
              }`}
              disabled={!isAppsActive || isUpdating}
            >
              Select Slideshow
            </button>
          </div>

          {/* Apps Section */}
          <div
            className={`border rounded p-3 transition-colors ${
              isAppsActive ? 'bg-white' : 'bg-gray-100'
            }`}
          >
            <h3 className="mb-2 text-xl font-bold text-center">Apps</h3>
            <button
              onClick={toggleLock}
              className={`w-full py-2 px-3 rounded flex items-center justify-center text-sm transition-colors mb-4 truncate ${
                isAppsActive
                  ? 'bg-yellow-500 text-white hover:bg-yellow-600'
                  : 'bg-gray-300 text-gray-500 cursor-not-allowed'
              }`}
              disabled={!isAppsActive || isUpdating}
            >
              {isLocked ? <Lock className="mr-2" /> : <Unlock className="mr-2" />}
              {isLocked ? 'Lock Apps' : 'Unlock Apps'}
            </button>
            <div className="grid grid-cols-2 gap-2">
              {availableModules.map((module) => (
                <div key={module.name} className="relative">
                  <button
                    onClick={() => toggleApp(module.name)}
                    className={`w-full h-12 rounded text-sm font-medium truncate transition-colors ${
                      isAppsActive
                        ? isAppActive(module.name)
                          ? `${module.color} text-white hover:${module.color}-600`
                          : 'bg-gray-300 hover:bg-gray-400 text-gray-800'
                        : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                    }`}
                    disabled={!isAppsActive || isUpdating}
                  >
                    {module.name}
                  </button>
                </div>
              ))}
            </div>
          </div>

          {/* Data Section */}
          <div
            className={`border rounded p-3 transition-colors ${
              isAppsActive ? 'bg-white' : 'bg-gray-100'
            }`}
          >
            <button
              onClick={() => handleStartApp(null, 'Data')}
              className={`w-full py-2 px-3 rounded text-sm transition-colors truncate ${
                isAppsActive
                  ? 'bg-indigo-500 text-white hover:bg-indigo-600'
                  : 'bg-gray-300 text-gray-500 cursor-not-allowed'
              }`}
              disabled={!isAppsActive || isUpdating}
            >
              View Data
            </button>
          </div>
        </div>

        {/* Footer Section */}
        <div className="p-4 border-t">
          <div className="mb-4">
            <button className="w-full px-3 py-2 text-sm text-white bg-purple-500 rounded hover:bg-purple-600">
              Open Settings
            </button>
          </div>
          <button
            onClick={() => setShowLogoutConfirm(true)} // Existing logout confirmation
            className="flex items-center justify-center w-full px-3 py-2 text-sm text-white bg-red-500 rounded hover:bg-red-600"
            disabled={isUpdating}
          >
            <LogOut className="mr-2" /> Log Out
          </button>
        </div>
      </div>

      {/* Logout Confirmation Popup */}
      {showLogoutConfirm && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-60">
          <div className="p-6 bg-white rounded-lg shadow-lg w-80">
            <h2 className="mb-4 text-xl font-semibold">Are you sure you want to log out?</h2>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowLogoutConfirm(false)}
                className="px-4 py-2 text-gray-800 bg-gray-300 rounded hover:bg-gray-400"
                disabled={isUpdating}
              >
                No
              </button>
              <button
                onClick={() => {
                  setShowLogoutConfirm(false);
                  handleLogout();
                }}
                className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600"
                disabled={isUpdating}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Class Activation/Deactivation Confirmation Popup */}
      {showClassConfirm && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-60">
          <div className="p-6 bg-white rounded-lg shadow-lg w-80">
            <h2 className="mb-4 text-xl font-semibold">Are you sure?</h2>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => {
                  setShowClassConfirm(false);
                  setClassToToggle(null);
                }}
                className="px-4 py-2 text-gray-800 bg-gray-300 rounded hover:bg-gray-400"
                disabled={isUpdating}
              >
                No
              </button>
              <button
                onClick={confirmToggleClass}
                className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
                disabled={isUpdating}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
