import React, { useState, useEffect } from 'react';
import { db, auth } from '../../firebase';
import { collection, getDocs, doc, updateDoc, arrayUnion, setDoc, deleteDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { Plus, Trash2, UserPlus } from 'lucide-react';
import CreateClass from './createclass';
import Modal from '../common/modal';

const ClassManagement = ({ onClose }) => {
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isAddStudentModalOpen, setIsAddStudentModalOpen] = useState(false);
  const [selectedClassId, setSelectedClassId] = useState(null);
  const [newStudentName, setNewStudentName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Fetch classes assigned to the current teacher
  const fetchClasses = async () => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        setErrorMessage('User not authenticated.');
        setLoading(false);
        return;
      }

      const teacherId = currentUser.uid;
      const classesRef = collection(db, `users/${teacherId}/classes`);
      const querySnapshot = await getDocs(classesRef);
      const classesData = querySnapshot.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data(),
      }));
      setClasses(classesData);
    } catch (error) {
      console.error('Error fetching classes:', error);
      setErrorMessage('Failed to fetch classes.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClasses();
  }, []);

  // Handle class deletion
  const handleDelete = async (classId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this class?');
    if (!confirmDelete) return;

    try {
      const teacherId = auth.currentUser.uid;
      await deleteDoc(doc(db, `users/${teacherId}/classes`, classId));
      setClasses(classes.filter((cls) => cls.id !== classId));
      alert('Class deleted successfully.');
    } catch (error) {
      console.error('Error deleting class:', error);
      alert('Failed to delete class.');
    }
  };

  // Add new class to state
  const addClassToState = (newClass) => {
    setClasses([...classes, newClass]);
  };

  // Open add student modal
  const openAddStudentModal = (classId) => {
    setSelectedClassId(classId);
    setIsAddStudentModalOpen(true);
  };

  // Handle adding a new student
  const handleAddStudent = async (e) => {
    e.preventDefault();
    if (!newStudentName.trim()) {
      alert('Please enter a student name.');
      return;
    }

    try {
      const teacherId = auth.currentUser.uid;
      const classRef = doc(db, `users/${teacherId}/classes`, selectedClassId);
      
      // Add student to users array
      await updateDoc(classRef, {
        users: arrayUnion(newStudentName.trim())
      });

      // Add student to students collection
      const studentRef = doc(collection(db, `users/${teacherId}/classes/${selectedClassId}/students`));
      await setDoc(studentRef, {
        name: newStudentName.trim(),
        ID: studentRef.id,
        category1: '',
        category2: '',
        category3: '',
        character: '',
        level: 0
      });

      // Update local state
      setClasses(classes.map(cls => 
        cls.id === selectedClassId 
          ? { ...cls, users: [...(cls.users || []), newStudentName.trim()] }
          : cls
      ));

      setNewStudentName('');
      setIsAddStudentModalOpen(false);
      alert('Student added successfully.');
    } catch (error) {
      console.error('Error adding student:', error);
      alert('Failed to add student.');
    }
  };

  return (
    <div className="relative">
      {/* Close Button */}
      {onClose && (
        <button
          onClick={onClose}
          className="absolute text-gray-500 top-2 right-2 hover:text-gray-700 focus:outline-none"
          aria-label="Close"
        >
          &times;
        </button>
      )}

      <h1 className="mb-6 text-3xl font-bold text-center">Class Management</h1>

      {/* Error Message */}
      {errorMessage && (
        <div className="p-4 mb-4 text-red-700 bg-red-100 border border-red-400 rounded">
          {errorMessage}
        </div>
      )}

      {/* Classes List */}
      <main>
        {loading ? (
          <div className="text-center">Loading classes...</div>
        ) : (
          <>
            {classes.length === 0 ? (
              <div className="flex flex-col items-center justify-center">
                <p className="mb-4 text-lg">You have no classes. Create one to get started.</p>
              </div>
            ) : (
              <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
                {classes.map((cls) => (
                  <div key={cls.id} className="p-6 bg-white rounded-lg shadow-md">
                    <h2 className="mb-2 text-2xl font-semibold">{cls.name}</h2>
                    <p className="mb-2">
                      <strong>Join Code:</strong> {cls['class code']}
                    </p>
                    <div className="mb-4">
                      <strong>Students:</strong>
                      {cls.users && cls.users.length > 0 ? (
                        <ul className="pl-4 mt-1 list-disc">
                          {cls.users.map((student, index) => (
                            <li key={index}>{student}</li>
                          ))}
                        </ul>
                      ) : (
                        <p className="mt-1 italic">No students yet</p>
                      )}
                    </div>
                    <div className="flex flex-wrap justify-between gap-2">
                      <Link
                        to={`/teacher/workbook/${cls.id}`}
                        className="flex items-center px-3 py-1 text-white bg-purple-500 rounded hover:bg-purple-600 focus:outline-none"
                      >
                        View Workbook
                      </Link>
                      <button
                        onClick={() => openAddStudentModal(cls.id)}
                        className="flex items-center px-3 py-1 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none"
                      >
                        <UserPlus size={16} className="mr-1" />
                        Add Student
                      </button>
                      <button
                        onClick={() => handleDelete(cls.id)}
                        className="flex items-center px-3 py-1 text-white bg-red-500 rounded hover:bg-red-600 focus:outline-none"
                      >
                        <Trash2 size={16} className="mr-1" />
                        Delete
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="flex justify-center mt-6">
              <button
                onClick={() => setIsCreateModalOpen(true)}
                className="flex items-center px-4 py-2 text-white bg-green-500 rounded-full hover:bg-green-600 focus:outline-none"
              >
                <Plus size={20} className="mr-2" />
                Create New Class
              </button>
            </div>
          </>
        )}
      </main>

      {/* Create Class Modal */}
      {isCreateModalOpen && (
        <Modal onClose={() => setIsCreateModalOpen(false)}>
          <CreateClass
            onClose={() => setIsCreateModalOpen(false)}
            addClass={addClassToState}
          />
        </Modal>
      )}

      {/* Add Student Modal */}
      {isAddStudentModalOpen && (
        <Modal onClose={() => setIsAddStudentModalOpen(false)}>
          <div className="p-6 bg-white rounded shadow">
            <h2 className="mb-4 text-xl font-bold">Add New Student</h2>
            <form onSubmit={handleAddStudent}>
              <label className="block mb-2 text-sm font-medium">Student Name:</label>
              <input
                type="text"
                value={newStudentName}
                onChange={(e) => setNewStudentName(e.target.value)}
                className="w-full px-3 py-2 mb-4 border rounded"
                required
              />
              <button
                type="submit"
                className="w-full px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none"
              >
                Add Student
              </button>
            </form>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ClassManagement;