import React, { useEffect, useRef, useState, useCallback } from 'react';
import { db } from '../../../firebase';
import { doc, setDoc, getDoc, onSnapshot, arrayUnion } from 'firebase/firestore';
import { Stage, Layer, Line, Circle, Rect } from 'react-konva';
import debounce from 'lodash.debounce';
import Toolbar from './Toolbar';

const TeacherWhiteboard = ({ show, handleClose, classID }) => {
  const [loading, setLoading] = useState(true);
  const [lines, setLines] = useState([]);
  const [pendingLines, setPendingLines] = useState([]);
  const [lastSyncedIndex, setLastSyncedIndex] = useState(0);
  const isDrawing = useRef(false);
  const stageRef = useRef(null);
  const containerRef = useRef(null); // Ref for the container

  const [toolSettings, setToolSettings] = useState({
    pen: { strokeColor: '#000000', strokeWidth: 4 },
    highlighter: { strokeColor: '#FFFF00', strokeWidth: 50 },
    eraser: { strokeWidth: 50 },
  });

  const [activeTool, setActiveTool] = useState('pen');
  const [isClearModalOpen, setIsClearModalOpen] = useState(false);
  const [backgroundSettings, setBackgroundSettings] = useState({
    type: 'blank',
    color: '#FFFFFF',
    lineColor: '#808080',
    spacing: 40,
    verticalLines: 1,
    horizontalLines: 1,
    verticalLineThickness: 5,
    horizontalLineThickness: 5,
  });

  const canvasWidth = 1600; // Updated canvas width
  const canvasHeight = 900; // Updated canvas height

  const whiteboardDocRef = doc(db, 'activeclasses', classID, 'whiteboard', 'data');

  // State for scaling
  const [scale, setScale] = useState(1);

  // Function to update scale based on container size
  const updateScale = () => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.clientWidth;
      const containerHeight = containerRef.current.clientHeight;

      // Calculate scale to fit the canvas within 90% of the container
      const scaleX = (containerWidth * 0.9) / canvasWidth;
      const scaleY = (containerHeight * 0.9) / canvasHeight;
      const newScale = Math.min(scaleX, scaleY, 1); // Ensure scale does not exceed 1

      setScale(newScale);
    }
  };

  // Initialize the whiteboard and set up real-time listener
  useEffect(() => {
    if (!classID) return;

    const initializeWhiteboard = async () => {
      try {
        const whiteboardDoc = await getDoc(whiteboardDocRef);
        if (whiteboardDoc.exists()) {
          const data = whiteboardDoc.data();
          setLines(data.drawingData || []);
          setLastSyncedIndex(data.drawingData ? data.drawingData.length : 0);
          setBackgroundSettings(data.backgroundSettings || { ...backgroundSettings });
        } else {
          await setDoc(whiteboardDocRef, {
            drawingData: [],
            backgroundSettings,
          });
        }
      } catch (error) {
        console.error('Error initializing whiteboard:', error);
      } finally {
        setLoading(false);
      }
    };

    initializeWhiteboard();

    const unsubscribe = onSnapshot(whiteboardDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        setLines(data.drawingData || []);
        setLastSyncedIndex(data.drawingData ? data.drawingData.length : 0);
        setBackgroundSettings(data.backgroundSettings || { ...backgroundSettings });
      }
    });

    return () => unsubscribe();
  }, [classID]);

  // Batch save function
  const batchSave = useCallback(async () => {
    if (pendingLines.length === 0) return;

    try {
      await setDoc(
        whiteboardDocRef,
        {
          drawingData: arrayUnion(...pendingLines),
        },
        { merge: true }
      );

      setLastSyncedIndex((prev) => prev + pendingLines.length);
      setPendingLines([]);
    } catch (error) {
      console.error('Error saving whiteboard data:', error);
    }
  }, [pendingLines, whiteboardDocRef]);

  // Set up interval for batch saving
  useEffect(() => {
    const saveInterval = setInterval(() => {
      batchSave();
    }, 3000); // Save every 3 seconds

    return () => clearInterval(saveInterval);
  }, [batchSave]);

  // Handle mouse events for drawing
  const handleMouseDown = () => {
    isDrawing.current = true;
    const stage = stageRef.current;
    if (!stage) return;
    const point = stage.getPointerPosition();
    const currentSettings = toolSettings[activeTool];
    const newLine = {
      tool: activeTool,
      points: [point.x, point.y],
      stroke: activeTool === 'eraser' ? '#FFFFFF' : currentSettings.strokeColor,
      strokeWidth:
        activeTool === 'eraser'
          ? currentSettings.strokeWidth * 3
          : currentSettings.strokeWidth,
      globalCompositeOperation:
        activeTool === 'eraser' ? 'destination-out' : 'source-over',
      opacity: activeTool === 'highlighter' ? 0.5 : 1,
    };
    setLines((prevLines) => [...prevLines, newLine]);
    setPendingLines((prevPending) => [...prevPending, newLine]);
  };

  const handleMouseMove = () => {
    if (!isDrawing.current) return;
    const stage = stageRef.current;
    if (!stage) return;
    const point = stage.getPointerPosition();
    setLines((prevLines) => {
      const lastLine = { ...prevLines[prevLines.length - 1] };
      lastLine.points = lastLine.points.concat([point.x, point.y]);
      return [...prevLines.slice(0, -1), lastLine];
    });
    setPendingLines((prevPending) => {
      const lastPending = { ...prevPending[prevPending.length - 1] };
      lastPending.points = lastPending.points.concat([point.x, point.y]);
      return [...prevPending.slice(0, -1), lastPending];
    });
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  // Function to clear the canvas
  const clearCanvas = () => {
    setIsClearModalOpen(true);
  };

  const confirmClearCanvas = async () => {
    setIsClearModalOpen(false);
    setLines([]);
    setPendingLines([]);
    setLastSyncedIndex(0);
    if (!classID) return;

    try {
      await setDoc(
        whiteboardDocRef,
        {
          drawingData: [],
        },
        { merge: true }
      );
    } catch (error) {
      console.error('Error clearing whiteboard:', error);
    }
  };

  // Functions to handle tool selection and settings
  const selectTool = (selectedTool) => {
    setActiveTool(selectedTool);
  };

  const updateToolSettings = (tool, settings) => {
    setToolSettings((prevSettings) => ({
      ...prevSettings,
      [tool]: {
        ...prevSettings[tool],
        ...settings,
      },
    }));
  };

  const handleColorSelect = (color) => {
    updateToolSettings(activeTool, { strokeColor: color });
  };

  // Functions to handle background settings
  const handleBackgroundColorSelect = (color) => {
    setBackgroundSettings((prevSettings) => ({
      ...prevSettings,
      color: color,
    }));
  };

  const handleLinesColorSelect = (color) => {
    setBackgroundSettings((prevSettings) => ({
      ...prevSettings,
      lineColor: color,
    }));
  };

  const handleBackgroundTypeChange = (type) => {
    setBackgroundSettings((prevSettings) => ({
      ...prevSettings,
      type: type,
    }));
  };

  const handleSpacingChange = (type, value, thickness) => {
    setBackgroundSettings((prev) => {
      if (type === 'vertical') {
        return { ...prev, verticalLines: value, verticalLineThickness: thickness };
      } else if (type === 'horizontal') {
        return { ...prev, horizontalLines: value, horizontalLineThickness: thickness };
      } else if (type === 'spacing') {
        return { ...prev, spacing: value, lineThickness: thickness };
      }
      return prev;
    });
  };

  // Save background settings (debounced)
  const saveBackgroundSettings = useCallback(
    debounce(async (settings) => {
      if (!classID) return;
      try {
        await setDoc(whiteboardDocRef, { backgroundSettings: settings }, { merge: true });
      } catch (error) {
        console.error('Error saving background settings:', error);
      }
    }, 5000),
    [classID]
  );

  // Effect to save background settings when they change
  useEffect(() => {
    saveBackgroundSettings(backgroundSettings);
  }, [backgroundSettings, saveBackgroundSettings]);

  // Handle cursor movement
  const [cursorPos, setCursorPos] = useState(null);
  const [isHovering, setIsHovering] = useState(false);

  const handleStageMouseMove = (e) => {
    const stage = stageRef.current;
    if (!stage) return;
    const pointer = stage.getPointerPosition();
    setCursorPos(pointer);
  };

  const handleStageMouseEnter = () => {
    setIsHovering(true);
  };

  const handleStageMouseLeave = () => {
    setIsHovering(false);
    setCursorPos(null);
  };

  // Function to render background
  const renderBackground = () => {
    const {
      type,
      color,
      lineColor,
      spacing,
      verticalLines,
      horizontalLines,
      verticalLineThickness,
      horizontalLineThickness,
    } = backgroundSettings;
    const stageWidth = canvasWidth;
    const stageHeight = canvasHeight;
    const linesArray = [];

    if (type === 'lines' || type === 'grid') {
      for (let y = spacing; y < stageHeight; y += spacing) {
        linesArray.push(
          <Line
            key={`h-${y}`}
            points={[0, y, stageWidth, y]}
            stroke={lineColor}
            strokeWidth={spacing / 10}
          />
        );
      }

      if (type === 'grid') {
        for (let x = spacing; x < stageWidth; x += spacing) {
          linesArray.push(
            <Line
              key={`v-${x}`}
              points={[x, 0, x, stageHeight]}
              stroke={lineColor}
              strokeWidth={spacing / 10}
            />
          );
        }
      }
    }

    if (type === 'cross') {
      const vSpacing = stageWidth / (verticalLines + 1);
      for (let i = 1; i <= verticalLines; i++) {
        linesArray.push(
          <Line
            key={`v-${i}`}
            points={[vSpacing * i, 0, vSpacing * i, stageHeight]}
            stroke={lineColor}
            strokeWidth={verticalLineThickness}
          />
        );
      }

      const hSpacing = stageHeight / (horizontalLines + 1);
      for (let i = 1; i <= horizontalLines; i++) {
        linesArray.push(
          <Line
            key={`h-${i}`}
            points={[0, hSpacing * i, stageWidth, hSpacing * i]}
            stroke={lineColor}
            strokeWidth={horizontalLineThickness}
          />
        );
      }
    }

    return (
      <Layer>
        <Rect x={0} y={0} width={stageWidth} height={stageHeight} fill={color} />
        {linesArray}
      </Layer>
    );
  };

  // Update scale on mount and when window resizes
  useEffect(() => {
    updateScale();
    window.addEventListener('resize', updateScale);
    return () => window.removeEventListener('resize', updateScale);
  }, []);

  if (!show) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="absolute inset-0 flex flex-col w-full h-full bg-white">
        <div className="flex items-center justify-between p-4 border-b">
          <h2 className="text-2xl font-semibold">Whiteboard</h2>
          <button
            onClick={handleClose}
            className="text-2xl font-bold text-gray-600 hover:text-gray-800 focus:outline-none"
            aria-label="Close Whiteboard"
          >
            &times;
          </button>
        </div>

        <Toolbar
          activeTool={activeTool}
          toolSettings={toolSettings}
          selectTool={selectTool}
          updateToolSettings={updateToolSettings}
          handleColorSelect={handleColorSelect}
          handleBackgroundColorSelect={handleBackgroundColorSelect}
          handleLinesColorSelect={handleLinesColorSelect}
          handleBackgroundTypeChange={handleBackgroundTypeChange}
          handleSpacingChange={handleSpacingChange}
          clearCanvas={clearCanvas}
          backgroundSettings={backgroundSettings}
          canvasWidth={canvasWidth}
        />

        <div className="flex items-center justify-center flex-1 p-0">
          {loading ? (
            <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
              <span className="text-gray-500">Loading Whiteboard...</span>
            </div>
          ) : (
            <div
              ref={containerRef} // Attach ref to the container
              className="relative flex items-center justify-center w-full h-full"
              style={{
                border: '10px solid grey',
                padding: '10px',
                backgroundColor: '#f0f0f0',
                boxSizing: 'border-box',
                overflow: 'hidden', // Prevent scrollbars
              }}
            >
              <Stage
                width={canvasWidth}
                height={canvasHeight}
                onMouseDown={handleMouseDown}
                onMouseMove={(e) => {
                  handleMouseMove(e);
                  handleStageMouseMove(e);
                }}
                onMouseUp={handleMouseUp}
                onMouseEnter={handleStageMouseEnter}
                onMouseLeave={handleStageMouseLeave}
                ref={stageRef}
                scale={{ x: scale, y: scale }} // Apply scaling
                style={{ background: '#ffffff', cursor: 'none' }}
              >
                {renderBackground()}

                <Layer>
                  {lines.map((line, i) => (
                    <Line
                      key={i}
                      points={line.points}
                      stroke={line.stroke}
                      strokeWidth={line.strokeWidth}
                      tension={0.5}
                      lineCap="round"
                      globalCompositeOperation={line.globalCompositeOperation}
                      opacity={line.opacity}
                    />
                  ))}
                </Layer>

                {isHovering && cursorPos && (
                  <Layer>
                    <Circle
                      x={cursorPos.x}
                      y={cursorPos.y}
                      radius={toolSettings[activeTool].strokeWidth / 2}
                      fill={
                        activeTool === 'eraser'
                          ? '#FFFFFF'
                          : toolSettings[activeTool].strokeColor
                      }
                      stroke="black"
                      strokeWidth={1}
                    />
                  </Layer>
                )}
              </Stage>
            </div>
          )}
        </div>

        {isClearModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-60">
            <div className="p-6 bg-white rounded-lg shadow-lg w-80">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-xl font-bold">Clear Whiteboard</h2>
                <button
                  onClick={() => setIsClearModalOpen(false)}
                  className="text-2xl font-bold text-gray-600 hover:text-gray-800 focus:outline-none"
                  aria-label="Close Clear Modal"
                >
                  &times;
                </button>
              </div>
              <p>Are you sure you want to clear the whiteboard?</p>
              <div className="flex justify-end mt-6 space-x-4">
                <button
                  onClick={() => setIsClearModalOpen(false)}
                  className="px-4 py-2 text-gray-700 bg-gray-200 rounded hover:bg-gray-300 focus:outline-none"
                >
                  Cancel
                </button>
                <button
                  onClick={confirmClearCanvas}
                  className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600 focus:outline-none"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeacherWhiteboard;
