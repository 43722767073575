// src/components/Contact.js

import React from 'react';

const Contact = () => {
  return (
    <div className="flex flex-col items-center min-h-screen p-4 bg-gray-50">
      <h1 className="mb-6 text-3xl font-semibold text-center text-gray-800">Contact Us</h1>
      <p className="mb-4 text-lg text-center text-gray-600">
        If you have any questions, please reach out to us:
      </p>
      <p className="mb-2 text-center text-gray-600">
        Email: <a href="mailto:jeremylukestoll@gmail.com" className="text-blue-500">jeremylukestoll@gmail.com</a>
      </p>
      {/* Add more contact information as needed */}
    </div>
  );
};

export default Contact;
