// src/components/Bee.js

import React, { useState } from 'react';
import beesolo from '../assets/beesolo.svg'; // Ensure the correct path
import './Bee.css'; // Import Bee-specific styles

const Bee = ({ id }) => {
  // Generates a random vertical position between 5% and 95%
  const getRandomTop = () => `${Math.floor(Math.random() * 91) + 5}%`;

  // Generates a random delay between 1s and 21s to prevent immediate visibility on refresh
  const getRandomDelay = () => `${(Math.random() * 20 + 1).toFixed(2)}s`; // 1s to 21s

  // Generates a random duration between 18s and 22s for speed variability
  const getRandomDuration = () => `${(Math.random() * 20 + 10).toFixed(2)}s`; // 18s to 22s

  const [top, setTop] = useState(getRandomTop());
  const [delay, setDelay] = useState(getRandomDelay());
  const [duration, setDuration] = useState(getRandomDuration());
  const [flipped, setFlipped] = useState(Math.random() < 0.5); // 50% chance to flip
  const [animationKey, setAnimationKey] = useState(0); // Key to re-trigger animation

  // Handles the end of the animation
  const handleAnimationEnd = () => {
    // Choose a new random vertical position
    setTop(getRandomTop());

    // Choose a new random delay
    setDelay(getRandomDelay());

    // Choose a new random duration for speed variability
    setDuration(getRandomDuration());

    // Optionally flip the bee for variety
    setFlipped(Math.random() < 0.5);

    // Increment the animation key to re-trigger the animation
    setAnimationKey(prev => prev + 1);
  };

  return (
    <img
      key={`bee-${id}-${animationKey}`} // Unique key to re-trigger animation
      src={beesolo}
      alt={`Flying Bee ${id + 1}`}
      className={`beesolo-animation ${flipped ? 'flipped' : ''}`}
      style={{ 
        top, 
        animationDelay: delay, 
        animationDuration: duration // Apply random duration
      }}
      onAnimationEnd={handleAnimationEnd}
      aria-hidden="true"
    />
  );
};

export default Bee;
