// src/components/Slideshow/Modal/SaveSlideshowModal.js

import React, { useState } from 'react';
import Button from '../../ui/Button';
import Input from '../../ui/Input';
import Textarea from '../../ui/Textarea';
import Card from '../../ui/Card';

const SaveSlideshowModal = ({ onClose, onSave }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [tags, setTags] = useState('');


  const handleSave = () => {
    if (!title.trim()) {
      alert('Please enter a slideshow title.');
      return;
    }
    const tagArray = tags.split(',').map(tag => tag.trim()).filter(tag => tag);
    onSave(title, description, tagArray,); // Ensure `slides` is accessible
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <Card className="w-1/3 p-6">
        <h2 className="mb-4 text-2xl font-bold">Save Slideshow</h2>
        <Input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Slideshow Title"
          className="mb-4"
        />
        <Textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Slideshow Description"
          className="mb-4"
        />
        <Input
          value={tags}
          onChange={(e) => setTags(e.target.value)}
          placeholder="Tags (comma separated)"
          className="mb-4"
        />
        <div className="flex justify-end">
          <Button onClick={onClose} variant="ghost" className="mr-2">
            Cancel
          </Button>
          <Button onClick={handleSave}>
            Save
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default SaveSlideshowModal;
