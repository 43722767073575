// src/components/Teachers/CreateClass.js

import React, { useState } from 'react';
import { db, auth } from '../../firebase';
import { collection, addDoc, setDoc, doc } from 'firebase/firestore';

const CreateClass = ({ onClose, addClass }) => {
  const [className, setClassName] = useState('');
  const [studentNamesText, setStudentNamesText] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Generate a random 6-letter code
  const generateClassCode = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return Array.from({ length: 6 }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join('');
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    if (!className) {
      setError('Class name is required.');
      setIsLoading(false);
      return;
    }

    const classCode = generateClassCode();
    const studentNames = studentNamesText.split('\n').filter(name => name.trim() !== '');

    try {
      const userId = auth.currentUser.uid;

      // Create the class document under the user's classes collection
      const classRef = await addDoc(collection(db, `users/${userId}/classes`), {
        name: className,
        code: classCode,
        teacherId: userId,
        classStatus: 'inactive', // Changed from 'active' to 'inactive'
        createdAt: new Date(),
        accessibleModules: [],
        signedInStudents: [],
        mode: 'unlocked',
        currentApp: null,
        'class code': classCode,
        users: studentNames.map(name => name.trim()) // Add student names to the users array
      });

      // Add students to the students subcollection
      const studentsPromises = studentNames.map(async (name) => {
        const studentDocRef = doc(collection(db, `users/${userId}/classes/${classRef.id}/students`));
        await setDoc(studentDocRef, {
          name: name.trim(),
          ID: studentDocRef.id,
          category1: '',
          category2: '',
          category3: '',
          character: '',
          level: 0
        });

        // Create an initial empty workbook for the student
        const workbookDocRef = doc(collection(db, `users/${userId}/classes/${classRef.id}/students/${studentDocRef.id}/workbook`));
        await setDoc(workbookDocRef, {
          content: "Initial workbook content",
          subject: '',
          subtopic: '',
          timestamp: new Date(),
          topic: ''
        });

        // Create an initial slide for the student
        const slideDocRef = doc(collection(db, `users/${userId}/classes/${classRef.id}/students/${studentDocRef.id}/slides`));
        await setDoc(slideDocRef, {
          result: '',
          subject: '',
          subtopic: '',
          timestamp: new Date(),
          topic: ''
        });
      });

      await Promise.all(studentsPromises);

      // Notify parent component
      addClass({ id: classRef.id, name: className, code: classCode, classStatus: 'inactive' }); // Include classStatus if needed
      onClose();
    } catch (err) {
      console.error('Error creating class:', err);
      setError('Failed to create class. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-6 bg-white rounded shadow">
      <h2 className="mb-4 text-xl font-bold">Create New Class</h2>
      <form onSubmit={handleSubmit}>
        <label className="block mb-2 text-sm font-medium">Class Name:</label>
        <input
          type="text"
          value={className}
          onChange={(e) => setClassName(e.target.value)}
          className="w-full px-3 py-2 mb-4 border rounded"
          required
        />

        <label className="block mb-2 text-sm font-medium">Student Names (one per line):</label>
        <textarea
          value={studentNamesText}
          onChange={(e) => setStudentNamesText(e.target.value)}
          className="w-full px-3 py-2 mb-4 border rounded"
          rows="10"
          placeholder="Enter student names, one per line"
        />

        <button
          type="submit"
          className="w-full px-4 py-2 text-white bg-green-500 rounded hover:bg-green-600 disabled:bg-green-300"
          disabled={isLoading}
        >
          {isLoading ? 'Creating Class...' : 'Create Class'}
        </button>
      </form>

      {/* Error Message */}
      {error && (
        <div className="p-2 mt-4 text-red-700 bg-red-100 border border-red-400 rounded">
          {error}
        </div>
      )}
    </div>
  );
};

export default CreateClass;
