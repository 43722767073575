// src/components/Slideshow/Modal/ImportSlideshowModal.js

import React, { useState, useRef } from 'react';
import { Upload } from 'lucide-react';
import Button from '../../ui/Button';
import Card from '../../ui/Card';
import { readPPTXFile } from '../../../utils/pptxParser'; // Updated import

const ImportSlideshowModal = ({ onClose, onImport }) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (
      selectedFile &&
      selectedFile.type ===
        'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    ) {
      setFile(selectedFile);
      setError(null);
    } else {
      setFile(null);
      setError('Please select a valid .pptx file.');
    }
  };

  const handleImport = async () => {
    if (!file) {
      setError('Please select a file to import.');
      return;
    }

    setLoading(true);
    try {
      const slideshowData = await readPPTXFile(file);
      onImport(slideshowData);
      onClose();
    } catch (err) {
      setError('Error parsing the PPTX file. Please try again.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <Card className="w-1/3 p-6">
        <h2 className="mb-4 text-2xl font-bold">Import Slideshow</h2>
        <div className="mb-4">
          <input
            type="file"
            accept=".pptx"
            onChange={handleFileChange}
            className="hidden"
            ref={fileInputRef}
          />
          <Button
            onClick={triggerFileInput}
            className="w-full p-4 border-2 border-gray-300 border-dashed rounded-md hover:border-gray-400"
          >
            <Upload className="w-6 h-6 mr-2" />
            {file ? file.name : 'Choose a PPTX file'}
          </Button>
        </div>
        {error && <p className="mb-4 text-red-500">{error}</p>}
        <div className="flex justify-end">
          <Button onClick={onClose} variant="ghost" className="mr-2">
            Cancel
          </Button>
          <Button onClick={handleImport} disabled={!file || loading}>
            {loading ? 'Importing...' : 'Import'}
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default ImportSlideshowModal;
