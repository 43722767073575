// src/components/SlideShow.js

import React, { useState, useEffect } from 'react';
import {
  XIcon,
  ArrowRightCircleIcon,
  ArrowLeftCircleIcon,
  PlusCircleIcon,
} from 'lucide-react';
import { db } from '../../firebase'; // Ensure firebase.js exports 'db'
import firebase from 'firebase/compat/app';

const SlideShow = ({ onClose, classId }) => {
  const [slides, setSlides] = useState([]);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [showSetupModal, setShowSetupModal] = useState(true);
  const [yearLevel, setYearLevel] = useState('');
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');

  // Fetch slides and currentSlide from Firestore in real-time
  useEffect(() => {
    if (!classId) {
      console.error('No classId provided to SlideShow component.');
      return;
    }

    const classRef = db.collection('classes').doc(classId);

    // Listener for slides
    const unsubscribeSlides = classRef
      .collection('slides')
      .orderBy('createdAt')
      .onSnapshot((snapshot) => {
        const fetchedSlides = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSlides(fetchedSlides);
      });

    // Listener for currentSlide
    const unsubscribeCurrentSlide = classRef.onSnapshot((doc) => {
      if (doc.exists) {
        const data = doc.data();
        setCurrentSlideIndex(data.currentSlide || 0);
      }
    });

    return () => {
      unsubscribeSlides();
      unsubscribeCurrentSlide();
    };
  }, [classId]);

  // Function to navigate to the next slide
  const nextSlide = () => {
    if (currentSlideIndex < slides.length - 1) {
      updateCurrentSlide(currentSlideIndex + 1);
    }
  };

  // Function to navigate to the previous slide
  const prevSlide = () => {
    if (currentSlideIndex > 0) {
      updateCurrentSlide(currentSlideIndex - 1);
    }
  };

  // Function to update the currentSlide in Firestore
  const updateCurrentSlide = (index) => {
    db.collection('classes')
      .doc(classId)
      .update({
        currentSlide: index,
      })
      .catch((error) => {
        console.error('Error updating currentSlide: ', error);
      });
  };

  // Function to add a new slide
  const addSlide = (type) => {
    if (!yearLevel || !subject) {
      alert('Please complete the setup before adding slides.');
      return;
    }

    const newSlideContent = `New Slide: ${type}`;
    const classRef = db.collection('classes').doc(classId);

    classRef
      .collection('slides')
      .add({
        content: newSlideContent,
        type: type,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        // Optionally, navigate to the new slide
        setTimeout(() => {
          updateCurrentSlide(slides.length); // New slide index
        }, 500);
      })
      .catch((error) => {
        console.error('Error adding new slide: ', error);
      });
  };

  // Function to handle setup completion
  const handleCreate = () => {
    if (!yearLevel || !subject) {
      alert('Year Level and Subject are required.');
      return;
    }

    // Initialize currentSlide if not set
    const classRef = db.collection('classes').doc(classId);
    classRef
      .get()
      .then((doc) => {
        if (doc.exists && doc.data().currentSlide === undefined) {
          classRef.update({ currentSlide: 0 });
        }
      })
      .catch((error) => {
        console.error('Error initializing currentSlide: ', error);
      });

    setShowSetupModal(false);
  };

  // Render current slide content
  const renderSlideContent = () => {
    if (slides.length === 0) {
      return <p className="text-xl text-gray-700">No slides available.</p>;
    }

    const currentSlide = slides[currentSlideIndex];
    return (
      <div className="flex flex-col items-center justify-center w-full h-full p-4 bg-white rounded-lg shadow-md">
        <h2 className="mb-4 text-3xl font-semibold">Slide {currentSlideIndex + 1}</h2>
        <p className="text-lg">{currentSlide.content}</p>
      </div>
    );
  };

  return (
    <div className="fixed inset-0 z-50 flex flex-col bg-white">
      {showSetupModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-full max-w-md p-8 bg-white rounded-lg">
            <h2 className="mb-6 text-2xl font-semibold">Slide Setup</h2>
            <select
              value={yearLevel}
              onChange={(e) => setYearLevel(e.target.value)}
              className="w-full p-2 mb-4 border border-gray-300 rounded"
            >
              <option value="">Select Year Level</option>
              <option value="Year 1">Year 1</option>
              <option value="Year 2">Year 2</option>
              <option value="Year 3">Year 3</option>
              {/* Add more year levels as needed */}
            </select>
            <select
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className="w-full p-2 mb-4 border border-gray-300 rounded"
            >
              <option value="">Select Subject</option>
              <option value="Math">Math</option>
              <option value="English">English</option>
              <option value="Science">Science</option>
              {/* Add more subjects as needed */}
            </select>
            <input
              type="text"
              placeholder="Optional content"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              className="w-full p-2 mb-4 border border-gray-300 rounded"
            />
            <button
              onClick={handleCreate}
              className="w-full px-4 py-2 text-white transition-colors duration-300 bg-blue-500 rounded-full hover:bg-blue-600"
            >
              Create
            </button>
          </div>
        </div>
      )}

      {!showSetupModal && (
        <>
          {/* Header */}
          <div className="flex items-center justify-between p-4 border-b">
            <h2 className="text-2xl font-semibold">Slide Show</h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <XIcon size={24} />
            </button>
          </div>

          {/* Slide Content */}
          <div className="flex items-center justify-center flex-grow px-4">
            {renderSlideContent()}
          </div>

          {/* Controls */}
          <div className="flex items-center justify-between p-4 border-t">
            <div className="flex items-center space-x-4">
              <button
                onClick={prevSlide}
                disabled={currentSlideIndex === 0}
                className={`p-3 bg-blue-100 rounded-full ${
                  currentSlideIndex === 0 ? 'cursor-not-allowed opacity-50' : 'hover:bg-blue-200'
                } transition-colors duration-300`}
              >
                <ArrowLeftCircleIcon size={24} />
              </button>
              <button
                onClick={nextSlide}
                disabled={currentSlideIndex === slides.length - 1}
                className={`p-3 bg-blue-100 rounded-full ${
                  currentSlideIndex === slides.length - 1 ? 'cursor-not-allowed opacity-50' : 'hover:bg-blue-200'
                } transition-colors duration-300`}
              >
                <ArrowRightCircleIcon size={24} />
              </button>
            </div>
            <div className="flex space-x-2">
              <button
                onClick={() => addSlide('Information')}
                className="p-2 text-white transition-colors duration-300 bg-orange-500 rounded-full hover:bg-orange-600"
              >
                <PlusCircleIcon size={20} />
              </button>
              <button
                onClick={() => addSlide('Writing Task')}
                className="p-2 text-white transition-colors duration-300 bg-orange-500 rounded-full hover:bg-orange-600"
              >
                <PlusCircleIcon size={20} />
              </button>
              <button
                onClick={() => addSlide('Drawing Task')}
                className="p-2 text-white transition-colors duration-300 bg-orange-500 rounded-full hover:bg-orange-600"
              >
                <PlusCircleIcon size={20} />
              </button>
              <button
                onClick={() => addSlide('Quiz')}
                className="p-2 text-white transition-colors duration-300 bg-orange-500 rounded-full hover:bg-orange-600"
              >
                <PlusCircleIcon size={20} />
              </button>
              <button
                onClick={() => addSlide('Reflection')}
                className="p-2 text-white transition-colors duration-300 bg-orange-500 rounded-full hover:bg-orange-600"
              >
                <PlusCircleIcon size={20} />
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SlideShow;
