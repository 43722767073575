// src/components/Teachers/TeacherHomepage.js

import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext'; // Import useAuth
import { db } from '../../firebase';
import { 
  doc, 
  getDoc, 
  collection, 
  query, 
  onSnapshot,
  getDocs,
  writeBatch,
  where,
} from 'firebase/firestore';
import TeacherWhiteboard from './Whiteboard/teacherwhiteboard';
import TeacherWorkbook from './teacherworkbook'; // Correct import path
import SlideshowApp from '../Slideshow/SlideshowApp';
import AssessmentApp from './AssessmentApp';
import ClassManagement from './classmanagement';
import '../../styles/TeacherHomepage.css';
import Sidebar from './Sidebar';
import Toolbar from './toolbar';
import logo from '../assets/beetitle.svg';
import { ChevronRight, ChevronLeft } from 'lucide-react';
import PropTypes from 'prop-types'; // Ensure PropTypes is imported if used

const TeacherHomepage = () => {
  // Access currentUser and userRole from AuthContext
  const { currentUser, userRole } = useAuth();

  // Sidebar State
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Toolbar State
  const [isToolbarVisible, setIsToolbarVisible] = useState(true);

  // Teacher States
  const [username, setUsername] = useState('');

  // Fullscreen App State
  const [fullscreenApp, setFullscreenApp] = useState(null);

  // Selected Classes State (Lifted up from Sidebar)
  const [selectedClasses, setSelectedClasses] = useState(new Set());

  // Classes State (Lifted up from Sidebar)
  const [classes, setClasses] = useState([]);

  // Class Codes Modal State
  const [showClassCodes, setShowClassCodes] = useState(false);
  const [classCodes, setClassCodes] = useState([]);

  // Class Management Modal State (New State)
  const [showClassManagement, setShowClassManagement] = useState(false);

  // Helper function to generate class codes
  const generateClassCode = (length) => {
    const characters = 'ACEFGHJKMNSTUVWXYZ2345678';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  // Fetch Teacher's Username and Classes
  useEffect(() => {
    let unsubscribeClasses = null; // To store the unsubscribe function

    const fetchData = async () => {
      if (currentUser) {
        try {
          // Fetch Username
          const userDocRef = doc(db, 'users', currentUser.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUsername(userData.name || 'Teacher');
          } else {
            setUsername('Teacher');
          }

          // Fetch Classes from 'users/{userID}/classes'
          const classesQuery = query(
            collection(db, `users/${currentUser.uid}/classes`)
          );
          unsubscribeClasses = onSnapshot(classesQuery, (snapshot) => {
            const fetchedClasses = snapshot.docs.map((docSnap) => ({
              id: docSnap.id,
              ...docSnap.data(),
            }));
            setClasses(fetchedClasses);
          });
        } catch (error) {
          console.error('Error fetching data:', error);
          setUsername('Teacher');
        }
      } else {
        // If user is signed out, clear classes and username
        setClasses([]);
        setUsername('');
      }
    };

    fetchData();

    // Cleanup function
    return () => {
      if (unsubscribeClasses) {
        unsubscribeClasses();
      }
    };
  }, [currentUser]);

  // Function to set all active classes to inactive
  const setClassesToInactive = async (userId) => {
    if (!userId) return; // Guard clause

    try {
      // Query active classes
      const activeClassesQuery = query(
        collection(db, `users/${userId}/classes`),
        where('classStatus', '==', 'active')
      );
      const activeClassesSnapshot = await getDocs(activeClassesQuery);

      if (!activeClassesSnapshot.empty) {
        const batch = writeBatch(db);
        activeClassesSnapshot.forEach((docSnap) => {
          const classDocRef = doc(db, `users/${userId}/classes`, docSnap.id);
          batch.update(classDocRef, {
            classStatus: 'inactive',
            mode: 'unlocked',
            currentApp: null,
            signedInStudents: [],
            'class code': generateClassCode(16),
            accessibleModules: [],
          });

          // Remove from 'activeclasses' collection
          const activeClassDocRef = doc(db, 'activeclasses', docSnap.id);
          batch.delete(activeClassDocRef);
        });

        await batch.commit();
        console.log('All active classes have been set to inactive.');
      }
    } catch (error) {
      console.error('Error setting classes to inactive on sign in:', error);
      // Optionally, notify the user or handle the error as needed
    }
  };

  // Handle Start Button Click for Apps
  const handleStartApp = (classID, appName) => {
    if (!classID && appName !== 'ClassManagement') {
      alert('Please select a class to start this app.');
      return;
    }
    setFullscreenApp({ appName, classID });
  };

  // Handle Close Fullscreen App
  const handleCloseApp = () => {
    setFullscreenApp(null);
  };

  // Toggle Sidebar
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  // Handle Class Codes Button Click
  const handleShowClassCodes = async () => {
    if (selectedClasses.size === 0) {
      alert('Please select at least one class to view class codes.');
      return;
    }

    try {
      const classCodesArray = [];
      const batch = [];

      selectedClasses.forEach((classID) => {
        const classDocRef = doc(db, `users/${currentUser.uid}/classes`, classID);
        batch.push(getDoc(classDocRef));
      });

      const classDocs = await Promise.all(batch);

      classDocs.forEach((docSnap) => {
        if (docSnap.exists()) {
          const classData = docSnap.data();
          classCodesArray.push({
            id: docSnap.id,
            name: classData.name,
            code: classData['class code'], // Updated to match Firestore field
            status: classData.classStatus, // Assuming classStatus field exists
          });
        }
      });

      setClassCodes(classCodesArray);
      setShowClassCodes(true);
    } catch (error) {
      console.error('Error fetching class codes:', error);
      alert('Failed to fetch class codes. Please try again.');
    }
  };

  // Handle Close Class Codes Modal
  const handleCloseClassCodes = () => {
    setShowClassCodes(false);
    setClassCodes([]);
  };

  // Handler to open Class Management Modal
  const handleManageClasses = () => {
    setShowClassManagement(true);
  };

  // Handler to close Class Management Modal
  const handleCloseClassManagement = () => {
    setShowClassManagement(false);
  };

  // Function to handle starting the Whiteboard with a valid classID
  const handleStartWhiteboard = () => {
    if (selectedClasses.size === 0) {
      alert('Please select at least one class to start the Whiteboard.');
      return;
    }

    // For simplicity, start Whiteboard with the first selected class
    const firstClassID = Array.from(selectedClasses)[0];
    handleStartApp(firstClassID, 'Whiteboard');
  };

  // Function to handle starting the Teacher Workbook with a valid classID
  const handleStartTeacherWorkbook = () => {
    if (selectedClasses.size === 0) {
      alert('Please select at least one class to open the Teacher Workbook.');
      return;
    }

    // For simplicity, open Teacher Workbook with the first selected class
    const firstClassID = Array.from(selectedClasses)[0];
    handleStartApp(firstClassID, 'Workbook');
  };

  // Determine the active classes (selected and active)
  const activeClasses = classes.filter(cls => selectedClasses.has(cls.id) && cls.classStatus === 'active');

  return (
    <div className="relative max-w-full min-h-screen p-6 bg-gradient-to-br from-blue-50 to-green-50">
      {/* Sidebar and Toggle Button */}
      <Sidebar
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        username={username}
        handleStartApp={handleStartApp}
        selectedClasses={selectedClasses}
        setSelectedClasses={setSelectedClasses}
        onManageClasses={handleManageClasses} // Pass the handler
        classes={classes} // Pass classes data
        setClasses={setClasses} // Pass setClasses function
      />
      <button
        onClick={toggleSidebar}
        className={`fixed left-0 top-1/2 transform -translate-y-1/2 bg-white text-blue-500 p-1 rounded-r-lg shadow-lg transition-all duration-300 ${
          isSidebarOpen ? 'translate-x-64' : 'translate-x-0'
        }`}
        style={{ height: '100px', zIndex: 40 }}
      >
        {isSidebarOpen ? <ChevronLeft size={40} /> : <ChevronRight size={40} />}
      </button>

      {/* Header with Logo */}
      <header className="flex items-center justify-center mb-6">
        <img src={logo} alt="Logo" className="w-auto h-40" />
      </header>

      {/* Active Class Codes Display */}
      {activeClasses.length > 0 && (
        <div className="mb-4">
          {activeClasses.map(cls => (
            <div key={cls.id} className="mb-2 text-4xl font-bold text-center text-black">
              <span className="font-semibold">Class Code:</span> {cls['class code']}
            </div>
          ))}
        </div>
      )}

      {/* Large White Rectangle Box */}
      <div className="max-w-5xl p-8 mx-auto mb-64 bg-white rounded-full shadow-lg">
        {/* You can add content inside this box if needed */}
      </div>

      {/* Tiles Section */}
      <div className="max-w-5xl mx-auto mb-16">
        <div className="grid grid-cols-2 gap-12">
          {/* Whiteboard Tile */}
          <div
            className="flex flex-col items-center justify-center p-6 transition-shadow bg-white rounded-lg shadow-lg cursor-pointer hover:shadow-xl"
            onClick={handleStartWhiteboard}
          >
            <h2 className="mb-2 text-xl font-semibold">Whiteboard</h2>
          </div>
          {/* Slides Tile */}
          <div
            className="flex flex-col items-center justify-center p-6 transition-shadow bg-white rounded-lg shadow-lg cursor-pointer hover:shadow-xl"
            onClick={() => {
              if (selectedClasses.size === 0) {
                alert('Please select at least one class to start Slides.');
                return;
              }
              const classID = Array.from(selectedClasses)[0];
              handleStartApp(classID, 'Slideshow');
            }}
          >
            <h2 className="mb-2 text-xl font-semibold">Slides</h2>
          </div>
          {/* Workbook Tile */}
          <div
            className="flex flex-col items-center justify-center p-6 transition-shadow bg-white rounded-lg shadow-lg cursor-pointer hover:shadow-xl"
            onClick={handleStartTeacherWorkbook}
          >
            <h2 className="mb-2 text-xl font-semibold">Workbook</h2>
          </div>
          {/* Book Quest Tile */}
          <div
            className="flex flex-col items-center justify-center p-6 transition-shadow bg-white rounded-lg shadow-lg cursor-pointer hover:shadow-xl"
            onClick={() => {
              if (selectedClasses.size === 0) {
                alert('Please select at least one class to start Book Quest.');
                return;
              }
              const classID = Array.from(selectedClasses)[0];
              handleStartApp(classID, 'BookQuest');
            }}
          >
            <h2 className="mb-2 text-xl font-semibold">Book Quest</h2>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="mt-16 text-center text-gray-600">
        <p>&copy; 2024 Learning Resources. All rights reserved.</p>
      </footer>

      {/* Toolbar Component */}
      <Toolbar
        isVisible={isToolbarVisible}
        toggleToolbar={() => setIsToolbarVisible(!isToolbarVisible)}
        handleShowClassCodes={handleShowClassCodes}
        handleStartApp={handleStartApp}
      />

      {/* Fullscreen App Overlays */}
      {fullscreenApp && fullscreenApp.appName && (
        <>
          {fullscreenApp.appName === 'Whiteboard' && (
            <TeacherWhiteboard
              show={true} // Ensure 'show' prop is true
              handleClose={handleCloseApp}
              classID={fullscreenApp.classID} // Corrected prop name
            />
          )}
          {fullscreenApp.appName === 'Workbook' && (
            <TeacherWorkbook
              onClose={handleCloseApp}
              classID={fullscreenApp.classID}
            />
          )}
          {fullscreenApp.appName === 'Slideshow' && (
            <SlideshowApp
              onClose={handleCloseApp}
              classID={fullscreenApp.classID}
            />
          )}
          {fullscreenApp.appName === 'Assessment' && (
            <AssessmentApp
              onClose={handleCloseApp}
              classID={fullscreenApp.classID}
            />
          )}
          {fullscreenApp.appName === 'ClassManagement' && (
            <ClassManagement
              onClose={handleCloseApp}
              classes={classes}
              setClasses={setClasses}
            />
          )}
          {/* Add other apps if necessary */}
        </>
      )}

      {/* Class Codes Modal */}
      {showClassCodes && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-11/12 max-w-md p-6 bg-white rounded-lg shadow-lg">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-2xl font-semibold">Class Codes</h2>
              <button
                onClick={handleCloseClassCodes}
                className="text-gray-500 hover:text-gray-700"
              >
                &times;
              </button>
            </div>
            <ul>
              {classCodes.length > 0 ? (
                classCodes.map((cls) => (
                  // Only display class codes for active classes
                  cls.status === 'active' && (
                    <li key={cls.id} className="mb-2">
                      <span className="font-semibold">{cls.name}:</span> <span className="font-semibold">Class Code:</span> {cls.code}
                    </li>
                  )
                ))
              ) : (
                <li>No class codes available.</li>
              )}
            </ul>
            <div className="mt-4 text-right">
              <button
                onClick={handleCloseClassCodes}
                className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Class Management Modal */}
      {showClassManagement && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-11/12 max-w-3xl p-6 bg-white rounded-lg shadow-lg">
            <ClassManagement
              onClose={handleCloseClassManagement}
              classes={classes}
              setClasses={setClasses}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TeacherHomepage;
