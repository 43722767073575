/* src/App.js */

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import LoginPage from './components/loginpage/loginpage'; // Ensure correct casing
import TeacherLoginPage from './components/loginpage/TeacherLoginPage'; // Import TeacherLoginPage
import TeacherHomepage from './components/Teachers/teacherhomepage'; // Ensure correct casing
import StudentHomepage from './components/Students/studenthomepage'; // Ensure correct casing
import About from './components/loginpage/about'; // Import About page
import Contact from './components/loginpage/contact'; // Import Contact page

import QuickThinkersLandingPage from './components/loginpage/QuickThinkersLandingPage'; // **Updated Import Path**
import Pricing from './components/loginpage/Pricing'; // **Updated Import Path**
import Solutions from './components/loginpage/Solutions'; // **Updated Import Path**
import WhyQuickThinkers from './components/loginpage/WhyQuickThinkers'; // **Updated Import Path**
import Resources from './components/loginpage/Resources'; // **Updated Import Path**

const PrivateRoute = ({ children, role }) => {
  const { currentUser, userRole } = useAuth();

  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }

  if (role && userRole !== role) {
    return <Navigate to="/" replace />;
  }

  return children;
};

function App() {
  return (
    <AuthProvider>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<QuickThinkersLandingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/teacherlogin" element={<TeacherLoginPage />} /> {/* Added Route */}
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />

        {/* New Public Routes */}
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/solutions" element={<Solutions />} />
        {/* Redirect old solution routes to the new hash-based URLs */}
        <Route path="/solutions/educators" element={<Navigate to="/solutions#educators" replace />} />
        <Route path="/solutions/schools-districts" element={<Navigate to="/solutions#schools-districts" replace />} />
        <Route path="/solutions/tutors" element={<Navigate to="/solutions#tutors" replace />} />
        <Route path="/solutions/higher-ed" element={<Navigate to="/solutions#higher-ed" replace />} />

        <Route path="/why-quick-thinkers" element={<WhyQuickThinkers />} />
        {/* Redirect old why-quick-thinkers routes to the new hash-based URLs */}
        <Route path="/why-quick-thinkers/customer-stories" element={<Navigate to="/why-quick-thinkers#customer-stories" replace />} />
        <Route path="/why-quick-thinkers/integration-partners" element={<Navigate to="/why-quick-thinkers#integration-partners" replace />} />
        <Route path="/why-quick-thinkers/efficacy" element={<Navigate to="/why-quick-thinkers#efficacy" replace />} />
        <Route path="/why-quick-thinkers/privacy" element={<Navigate to="/why-quick-thinkers#privacy" replace />} />

        <Route path="/resources" element={<Resources />} />
        {/* Redirect old resources routes to the new hash-based URLs */}
        <Route path="/resources/blog" element={<Navigate to="/resources#blog" replace />} />
        <Route path="/resources/help-center" element={<Navigate to="/resources#help-center" replace />} />
        <Route path="/resources/webinars" element={<Navigate to="/resources#webinars" replace />} />

        {/* Protected Routes */}
        <Route
          path="/teacher"
          element={
            <PrivateRoute role="teacher">
              <TeacherHomepage />
            </PrivateRoute>
          }
        />
        <Route
          path="/student"
          element={
            <PrivateRoute role="student">
              <StudentHomepage />
            </PrivateRoute>
          }
        />

        {/* Fallback Route for 404 Not Found */}
        <Route path="*" element={<Navigate to="/" replace />} /> {/* Redirect unknown routes to Home or create a NotFound component */}
      </Routes>
    </AuthProvider>
  );
}

export default App;
