/* src/components/Pricing.js */

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CheckCircle } from 'lucide-react';
import { Helmet } from 'react-helmet'; // Import React Helmet for SEO

// Import Layout
import Layout from './Layout'; // Import the Layout component

const Pricing = () => {
  const [isAnnual, setIsAnnual] = useState(false);
  const [showComingSoon, setShowComingSoon] = useState(false); // Controls popup visibility
  const [fadeOut, setFadeOut] = useState(false); // Controls popup fade-out

  const toggleBilling = () => {
    setIsAnnual(!isAnnual);
  };

  const handleRequestQuote = () => {
    setShowComingSoon(true);
    setFadeOut(false);

    // Start fade-out after 1 second
    setTimeout(() => {
      setFadeOut(true);
    }, 1000); // 1000ms = 1 second

    // Hide popup after fade-out transition (e.g., 500ms)
    setTimeout(() => {
      setShowComingSoon(false);
    }, 1500); // Total duration: 1.5 seconds
  };

  const freeFeatures = [
    'Digital classroom whiteboard',
    'Slide manager and Quick Thinkers Library',
    'Gamified postive behaviour management system',
    'Digital workbook',
    'Dynamic learning and assessment based games',
    'Digital classroom whiteboard',
    'Digital classroom whiteboard',
    'Up to 3 hives (classes)',
    'Teacher dashboard with reporting and data analytics',
    'Live classroom management tools',


  ];

  const premiumFeatures = [
    'All Free features',
    'Up to 10 hives (classes)',
    'Priority email support',
    'Instantly generate AI lessons and videos',
    'Instantly generate digital texts',
    'Instantly assess student writing against national benchamarks with AI',
    'AI powered Book Quest resource',
    'Custom integrations',
  ];

  const schoolFeatures = [
    'Create and manage a centralized School Library',
    'Manage and maintain oversight of learning materials and assessments',
    'Access to Real-Time Efficacy Reports with usage metrics and data analytics',
    'Customized professional development and training programs',
    'Dedicated support for smooth onboarding and implementation',
    'Personalized implementation services, including integration with existing school systems',
    'Comprehensive school-wide attendance tracking and advanced data analytics',
  ];

  return (
    <Layout>
      {/* SEO Optimization */}
      <Helmet>
        <title>Pricing | Quick Thinkers</title>
        <meta
          name="description"
          content="Explore Quick Thinkers' pricing plans. Choose between our Free tier with essential features, Premium for advanced tools, or our School tier for comprehensive school-wide solutions."
        />
        <meta
          name="keywords"
          content="Quick Thinkers, pricing, education tools, teacher resources, premium education software, school solutions"
        />
        <link rel="canonical" href="https://www.quickthinkers.com/pricing" />
      </Helmet>

      {/* Pricing Section */}
      <section className="relative px-6 py-20 bg-yellow-100">
        <h2 className="mb-12 text-4xl font-bold text-center text-yellow-900 animate-fadeIn">
          Choose Your Plan
        </h2>

        <div className="flex flex-col items-center justify-center space-y-12 md:flex-row md:space-y-0 md:space-x-12">
          {/* Free Tier */}
          <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-lg transform transition-transform duration-500 hover:translate-y-[-10px] hover:shadow-2xl">
            <h3 className="mb-4 text-2xl font-semibold text-center text-yellow-700">Free</h3>
            <p className="mb-6 text-center text-gray-600">
              Get started with essential features to boost student engagement.
            </p>
            <div className="mb-6 text-center">
              <span className="text-4xl font-bold text-gray-800">$0</span>
              <span className="text-gray-600"> / month</span>
            </div>
            <ul className="mb-6 space-y-4">
              {freeFeatures.map((feature, index) => (
                <FeatureItem key={index} text={feature} />
              ))}
            </ul>
            <Link
              to="/signup/free"
              className="w-full px-4 py-2 text-center text-white transition-colors duration-300 transform bg-yellow-600 rounded-full hover:bg-yellow-500 hover:scale-105"
            >
              Get Started
            </Link>
          </div>

          {/* Premium Tier */}
          <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-lg transform transition-transform duration-500 hover:translate-y-[-10px] hover:shadow-2xl">
            <h3 className="mb-4 text-2xl font-semibold text-center text-yellow-700">Premium</h3>
            <p className="mb-6 text-center text-gray-600">
              Unlock advanced features for enhanced teaching and learning experiences.
            </p>

            {/* Billing Toggle */}
            <div className="flex items-center justify-center mb-6 space-x-4">
              <span
                className={`transition-colors duration-300 ${
                  !isAnnual ? 'font-bold text-yellow-700' : 'text-gray-500'
                }`}
              >
                Monthly
              </span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={isAnnual}
                  onChange={toggleBilling}
                  aria-label="Toggle billing cycle"
                />
                <div className="h-6 transition-colors duration-300 bg-gray-200 rounded-full w-11 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-yellow-300 peer-checked:bg-yellow-600"></div>
              </label>
              <span
                className={`transition-colors duration-300 ${
                  isAnnual ? 'font-bold text-yellow-700' : 'text-gray-500'
                }`}
              >
                Annually
              </span>
            </div>

            <div className="mb-6 text-center">
              <span className="text-4xl font-bold text-gray-800">
                {isAnnual ? '$50' : '$5'}
              </span>
              <span className="text-gray-600"> / {isAnnual ? 'year' : 'month'}</span>
            </div>
            <ul className="mb-6 space-y-4">
              {premiumFeatures.map((feature, index) => (
                <FeatureItem key={index} text={feature} />
              ))}
            </ul>
            <Link
              to="/signup/premium"
              className="w-full px-4 py-2 text-center text-white transition-colors duration-300 transform bg-yellow-600 rounded-full hover:bg-yellow-500 hover:scale-105"
            >
              {isAnnual ? 'Upgrade Annually' : 'Upgrade Now'}
            </Link>
          </div>

          {/* School Tier */}
          <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-lg transform transition-transform duration-500 hover:translate-y-[-10px] hover:shadow-2xl">
            <h3 className="mb-4 text-2xl font-semibold text-center text-yellow-700">School</h3>
            <p className="mb-6 text-center text-gray-600">
              Comprehensive solutions designed to manage and enhance your entire school's learning environment.
            </p>
            <div className="relative mb-6 text-center">
              {/* Clickable "Request a quote" Button */}
              <button
                onClick={handleRequestQuote}
                className="px-4 py-2 font-semibold text-white transition-colors duration-300 bg-yellow-600 rounded-full hover:bg-yellow-500"
                aria-label="Request a quote"
              >
                Request a quote
              </button>

              {/* Popup Bubble */}
              {showComingSoon && (
                <div
                  className={`absolute bottom-full mb-2 px-6 py-3 bg-white text-gray-700 rounded-lg shadow-md transition-opacity duration-500 left-1/2 transform -translate-x-1/2 ${
                    fadeOut ? 'opacity-0' : 'opacity-100'
                  }`}
                >
                  Coming Soon
                </div>
              )}
            </div>
            <ul className="mb-6 space-y-4">
              {schoolFeatures.map((feature, index) => (
                <FeatureItem key={index} text={feature} />
              ))}
            </ul>
            <div className="w-full px-4 py-2 text-center text-gray-700">
              Contact us to tailor a plan that fits your school's unique needs.
            </div>
          </div>
        </div>

        {/* Optional: Add a CTA Section */}
        <section className="px-6 py-20 bg-yellow-50">
          <h2 className="mb-6 text-3xl font-bold text-center text-yellow-900 animate-fadeIn">
            Ready to Enhance Your Teaching?
          </h2>
          <p className="mb-8 text-center text-gray-600 delay-200 animate-fadeIn">
            Join thousands of educators who are transforming their classrooms with Quick Thinkers.
          </p>
          <div className="flex justify-center">
            <Link
              to="/signup"
              className="px-8 py-3 text-xl font-semibold text-white transition-colors duration-300 transform bg-yellow-600 rounded-full hover:bg-yellow-500 hover:scale-105 animate-fadeIn delay-400"
            >
              Get Started Today
            </Link>
          </div>
        </section>
      </section>
    </Layout>
  );
};

// FeatureItem Component with Animation
const FeatureItem = ({ text }) => (
  <div className="flex items-center">
    <CheckCircle className="w-6 h-6 text-green-500 animate-pulse" />
    <span className="ml-3 text-gray-700 transition-transform duration-300 transform hover:translate-x-2">
      {text}
    </span>
  </div>
);

export default Pricing;