// src/components/Slideshow/SlideshowLibrary.js

import React, { useState, useEffect } from 'react';
import Button from '../ui/Button';
import Card from '../ui/Card';
import Input from '../ui/Input';
import { db } from '../../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';

const SlideshowLibrary = ({ onImport }) => {
  const [librarySlideshows, setLibrarySlideshows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState({ subject: '', grade: '' });

  useEffect(() => {
    const fetchSlideshows = async () => {
      try {
        let q = collection(db, 'slideshows');

        // Apply search filter
        if (searchQuery) {
          q = query(q, where('title', '>=', searchQuery), where('title', '<=', searchQuery + '\uf8ff'));
        }

        const snapshot = await getDocs(q);
        const slideshows = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setLibrarySlideshows(slideshows);
      } catch (error) {
        console.error('Error fetching slideshows:', error);
      }
    };

    fetchSlideshows();
  }, [searchQuery, filters]);

  return (
    <div>
      {/* Search and Filters */}
      <div className="flex mb-4">
        <Input
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search slideshows..."
          className="mr-2"
        />
        {/* Add filter components as needed */}
      </div>

      {/* Library Slideshows */}
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        {librarySlideshows.map(slideshow => (
          <Card key={slideshow.id}>
            <h3 className="text-xl font-semibold">{slideshow.title}</h3>
            <p className="text-sm text-gray-600">{slideshow.description}</p>
            <div className="mt-2">
              <Button onClick={() => onImport(slideshow)} className="w-full">
                Import Slideshow
              </Button>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default SlideshowLibrary;
