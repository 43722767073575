import React, { useState } from 'react';
import {
  PlusCircle,
  Image,
  HelpCircle,
  FileText,
  Trash2,
  ArrowLeft,
} from 'lucide-react';
import Button from '../ui/Button';
import Card from '../ui/Card';
import Input from '../ui/Input';
import Checkbox from '../ui/Checkbox';
import Textarea from '../ui/Textarea';
import ImportSlideshowModal from './Modal/ImportSlideshowModal';

const SlideshowCreationTool = ({ initialData }) => {
  const [title, setTitle] = useState(initialData?.title || '');
  const [description, setDescription] = useState(
    initialData?.description || ''
  );
  const [tags, setTags] = useState(initialData?.tags?.join(', ') || '');
  const [slides, setSlides] = useState(
    initialData?.slides.length > 0
      ? initialData.slides
      : [{ id: 1, content: null, subtitle: '' }]
  );
  const [currentSlide, setCurrentSlide] = useState(
    initialData?.slides.length > 0 ? initialData.slides[0].id : 1
  );
  const [showImportModal, setShowImportModal] = useState(false);

  const addSlide = () => {
    const newSlide = {
      id: slides.length + 1,
      content: null,
      subtitle: '',
    };
    setSlides([...slides, newSlide]);
    setCurrentSlide(newSlide.id);
  };

  const setContent = (type) => {
    const updatedSlides = slides.map((slide) => {
      if (slide.id === currentSlide) {
        let newContent;
        switch (type) {
          case 'Image':
            newContent = { type: 'image', src: '/api/placeholder/200/150' };
            break;
          case 'Question':
            newContent = {
              type: 'question',
              question: '',
              options: [
                { text: '', correct: false },
                { text: '', correct: false },
              ],
            };
            break;
          case 'Fact':
            newContent = { type: 'fact', text: '' };
            break;
          default:
            newContent = null;
        }
        return { ...slide, content: newContent };
      }
      return slide;
    });
    setSlides(updatedSlides);
  };

  const updateContent = (field, value, optionIndex) => {
    const updatedSlides = slides.map((slide) => {
      if (slide.id === currentSlide) {
        if (field === 'subtitle') {
          return { ...slide, subtitle: value };
        }
        if (slide.content) {
          const updatedContent = { ...slide.content };

          if (updatedContent.type === 'question') {
            if (field === 'question') {
              updatedContent.question = value;
            } else if (field === 'option') {
              updatedContent.options[optionIndex].text = value;
            } else if (field === 'correct') {
              updatedContent.options = updatedContent.options.map(
                (opt, idx) => ({
                  ...opt,
                  correct: idx === optionIndex,
                })
              );
            } else if (field === 'deleteOption') {
              updatedContent.options = updatedContent.options.filter(
                (_, idx) => idx !== optionIndex
              );
            } else if (
              field === 'addOption' &&
              updatedContent.options.length < 8
            ) {
              updatedContent.options.push({ text: '', correct: false });
            }
          } else if (updatedContent.type === 'fact') {
            updatedContent.text = value;
          }
          // Handle formattedText editing if needed
          else if (updatedContent.type === 'formattedText') {
            // Optionally allow editing of formatted text
          }

          return { ...slide, content: updatedContent };
        }
      }
      return slide;
    });
    setSlides(updatedSlides);
  };

  const renderFormattedText = (textElements) => {
    return textElements.map((element, index) => {
      let formattedText = element.text;

      const style = {};
      if (element.bold) style.fontWeight = 'bold';
      if (element.italic) style.fontStyle = 'italic';
      if (element.underline) style.textDecoration = 'underline';
      if (element.fontSize) style.fontSize = `${element.fontSize}px`;
      if (element.color) style.color = element.color;

      return (
        <span key={index} style={style}>
          {formattedText}
        </span>
      );
    });
  };

  const renderSlideContent = (slide, isThumbnail = false) => {
    if (!slide) return null;

    const content = slide.content;

    const subtitleElement = (
      <div
        className={`${
          isThumbnail ? 'text-xs' : 'text-lg'
        } font-semibold mb-2`}
      >
        {slide.subtitle || (isThumbnail ? 'No subtitle' : '')}
      </div>
    );

    if (!content) {
      return isThumbnail ? subtitleElement : null;
    }

    switch (content.type) {
      case 'formattedText':
        return (
          <>
            {subtitleElement}
            <div
              className={`text-container ${
                isThumbnail ? 'text-xs' : 'text-base'
              }`}
            >
              {renderFormattedText(content.textElements)}
            </div>
          </>
        );
      case 'image':
        return (
          <>
            {subtitleElement}
            <img
              src={content.src}
              alt="Placeholder"
              className={`w-full h-auto ${
                isThumbnail ? 'max-h-16' : 'mb-2'
              }`}
            />
          </>
        );
      case 'question':
        return (
          <>
            {subtitleElement}
            {isThumbnail ? (
              <div className="overflow-hidden text-xs">
                <div className="font-bold truncate">
                  {content.question || 'New Question'}
                </div>
                {content.options.map((option, index) => (
                  <div key={index} className="truncate">
                    {option.correct ? '✓' : '○'}{' '}
                    {option.text || `Option ${index + 1}`}
                  </div>
                ))}
              </div>
            ) : (
              <div className="mb-4">
                <Input
                  value={content.question}
                  onChange={(e) => updateContent('question', e.target.value)}
                  placeholder="Enter question"
                  className="mb-2"
                />
                {content.options.map((option, optIndex) => (
                  <div key={optIndex} className="flex items-center mb-1">
                    <Button
                      variant="ghost"
                      onClick={() =>
                        updateContent('deleteOption', null, optIndex)
                      }
                      className="mr-2"
                    >
                      <Trash2 className="w-4 h-4" />
                    </Button>
                    <Input
                      value={option.text}
                      onChange={(e) =>
                        updateContent('option', e.target.value, optIndex)
                      }
                      placeholder={`Option ${optIndex + 1}`}
                      className="mr-2"
                    />
                    <Checkbox
                      checked={option.correct}
                      onChange={(e) =>
                        updateContent('correct', e.target.checked, optIndex)
                      }
                    />
                  </div>
                ))}
                {content.options.length < 8 && (
                  <Button
                    variant="outline"
                    onClick={() => updateContent('addOption')}
                    className="mt-2"
                  >
                    <PlusCircle className="w-4 h-4 mr-2" /> Add Option
                  </Button>
                )}
              </div>
            )}
          </>
        );
      case 'fact':
        return (
          <>
            {subtitleElement}
            {isThumbnail ? (
              <div className="text-xs truncate">
                {content.text || 'New Fact'}
              </div>
            ) : (
              <Textarea
                value={content.text}
                onChange={(e) => updateContent('fact', e.target.value)}
                placeholder="Enter fact"
                className="mb-2"
              />
            )}
          </>
        );
      default:
        return null;
    }
  };

  const currentSlideData = slides.find((slide) => slide.id === currentSlide);

  const handleImport = (importedData) => {
    setTitle(importedData.title);
    setDescription(importedData.description);
    setTags(importedData.tags.join(', '));
    setSlides(importedData.slides);
    setCurrentSlide(importedData.slides[0].id);
    setShowImportModal(false);
  };

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      {/* Top panel */}
      <div className="flex items-center p-4 space-x-4 bg-white border-b">
        {/* Back to Menu Button */}
        <Button
          onClick={() => {
            /* Navigate back to menu */
          }}
        >
          <ArrowLeft className="w-4 h-4 mr-2" /> Back to Menu
        </Button>
        {/* Input Fields */}
        <Input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter slideshow title"
          className="flex-1"
        />
        <Input
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter slideshow description"
          className="flex-1"
        />
        <Input
          value={tags}
          onChange={(e) => setTags(e.target.value)}
          placeholder="Enter tags (comma separated)"
          className="flex-1"
        />
      </div>

      {/* Main content area with sidebars */}
      <div className="flex flex-1">
        {/* Left sidebar */}
        <div className="w-48 p-4 overflow-y-auto bg-white border-r">
          <div className="space-y-2">
            {slides.map((slide) => (
              <div
                key={slide.id}
                className={`aspect-video cursor-pointer border p-1 ${
                  currentSlide === slide.id
                    ? 'border-blue-500'
                    : 'border-gray-300'
                }`}
                onClick={() => setCurrentSlide(slide.id)}
              >
                <div className="w-full h-full overflow-hidden text-xs">
                  {renderSlideContent(slide, true)}
                </div>
              </div>
            ))}
          </div>
          <Button onClick={addSlide} className="w-full mt-4">
            <PlusCircle className="w-4 h-4 mr-2" /> Add Slide
          </Button>
        </div>

        {/* Main content area */}
        <div className="flex-1 p-4 overflow-y-auto">
          <Card className="h-full p-4">
            <h2 className="mb-4 text-2xl font-bold text-center">
              {title || 'My Slideshow'}
            </h2>
            <Textarea
              value={currentSlideData?.subtitle || ''}
              onChange={(e) => updateContent('subtitle', e.target.value)}
              placeholder="Enter slide subtitle"
              className="mb-4"
            />
            {currentSlideData && (
              <>
                {renderSlideContent(currentSlideData)}
                {/* The "+" button below the main slide preview has been removed */}
              </>
            )}
          </Card>
        </div>

        {/* Right sidebar */}
        <div className="w-48 p-4 bg-white border-l">
          <h2 className="mb-4 text-xl font-bold text-center">Type</h2>
          <Button onClick={() => setContent('Image')} className="w-full mb-2">
            <Image className="w-4 h-4 mr-2" /> Set Image
          </Button>
          <Button onClick={() => setContent('Question')} className="w-full mb-2">
            <HelpCircle className="w-4 h-4 mr-2" /> Set Question
          </Button>
          <Button onClick={() => setContent('Fact')} className="w-full mb-2">
            <FileText className="w-4 h-4 mr-2" /> Set Fact
          </Button>
        </div>
      </div>

      {/* Import Slideshow Modal (Removed the trigger button) */}
      {showImportModal && (
        <ImportSlideshowModal
          onClose={() => setShowImportModal(false)}
          onImport={handleImport}
        />
      )}
    </div>
  );
};

export default SlideshowCreationTool;
