/* src/components/loginpage/About.js */

import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // For SEO
import { ChevronLeft, CheckCircle } from 'lucide-react'; // Import CheckCircle icon
import { motion } from 'framer-motion'; // For animations

// Import Layout
import Layout from './Layout'; // Import the Layout component

// FeatureItem Component (Reusable)
const FeatureItem = ({ text }) => (
  <motion.div
    className="flex items-start p-4 space-x-3 transition-shadow duration-300 bg-white rounded-lg shadow hover:shadow-lg"
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5 }}
  >
    <CheckCircle className="w-6 h-6 mt-1 text-green-500" />
    <p className="text-gray-700">{text}</p>
  </motion.div>
);

// TestimonialItem Component (Reusable)
const TestimonialItem = ({ testimonial }) => (
  <motion.div
    className="p-6 transition-shadow duration-300 bg-white rounded-lg shadow-md hover:shadow-xl"
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5 }}
  >
    <p className="mb-4 italic text-gray-600">"{testimonial.feedback}"</p>
    <div className="flex items-center">
      <div className="flex-shrink-0 w-12 h-12 mr-4 bg-gray-200 rounded-full"></div>
      <div>
        <h5 className="text-lg font-semibold text-gray-800">{testimonial.name}</h5>
        <p className="text-sm text-gray-500">{testimonial.role}</p>
      </div>
    </div>
  </motion.div>
);

// BenefitsSection Component (Reusable)
const BenefitsSection = ({ benefits }) => (
  <motion.div
    className="mt-12"
    initial={{ opacity: 0 }}
    whileInView={{ opacity: 1 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5 }}
  >
    <h3 className="mb-6 text-2xl font-bold text-yellow-900">Our Benefits</h3>
    <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
      {benefits.map((benefit, index) => (
        <motion.div
          key={index}
          className="flex items-start p-4 space-x-3 bg-yellow-100 rounded-lg"
          initial={{ opacity: 0, scale: 0.9 }}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: index * 0.1 }}
        >
          <CheckCircle className="w-6 h-6 mt-1 text-yellow-600" />
          <p className="text-gray-700">{benefit}</p>
        </motion.div>
      ))}
    </div>
  </motion.div>
);

// TestimonialsSection Component (Reusable)
const TestimonialsSection = ({ testimonials }) => (
  <motion.div
    className="mt-12"
    initial={{ opacity: 0 }}
    whileInView={{ opacity: 1 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5 }}
  >
    <h3 className="mb-6 text-2xl font-bold text-yellow-900">What Our Clients Say</h3>
    <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
      {testimonials.map((testimonial, index) => (
        <TestimonialItem key={index} testimonial={testimonial} />
      ))}
    </div>
  </motion.div>
);

// AnimatedBee Component
const AnimatedBee = () => (
  <motion.img
    src="/assets/beesolo.svg" // Adjust the path if necessary
    alt="Animated Bee"
    className="absolute w-12 h-12"
    initial={{ x: 0, y: 0 }}
    animate={{
      x: [0, 100, -100, 0],
      y: [0, -50, 50, 0],
    }}
    transition={{
      duration: 10,
      repeat: Infinity,
      ease: 'linear',
    }}
  />
);

// AboutPageData (Optional: Centralizing content)
const aboutData = {
  mission: {
    title: 'Our Mission',
    content:
      'At Quick Thinkers, our mission is to empower educators and institutions with innovative tools that enhance the learning experience. We strive to simplify administrative tasks, foster engagement, and promote data-driven decision-making to create effective and dynamic educational environments.',
  },
  story: {
    title: 'Our Story',
    content:
      'Founded in 2020, Quick Thinkers emerged from a passion for education and technology. Our team of educators, developers, and designers came together to create a platform that addresses the real-world challenges faced by teachers and administrators. Over the years, we have grown and evolved, continually adapting to the changing needs of the educational landscape.',
  },
  team: {
    title: 'Meet the Team',
    members: [
      {
        name: 'Alice Johnson',
        role: 'Chief Executive Officer',
        photo: '/assets/team/alice.jpg', // Placeholder path
        bio: 'Alice leads the team with a vision to revolutionize education through technology.',
      },
      {
        name: 'Bob Smith',
        role: 'Chief Technology Officer',
        photo: '/assets/team/bob.jpg', // Placeholder path
        bio: 'Bob oversees the development and implementation of our innovative solutions.',
      },
      {
        name: 'Carol Martinez',
        role: 'Head of Education',
        photo: '/assets/team/carol.jpg', // Placeholder path
        bio: 'Carol ensures our products meet the highest educational standards.',
      },
      // Add more team members as needed
    ],
  },
  contact: {
    title: 'Get in Touch',
    content:
      'Have questions or need support? Our team is here to help you navigate and maximize the benefits of Quick Thinkers.',
    email: 'support@quickthinkers.com',
    phone: '(123) 456-7890',
  },
};

const About = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.slice(1); // Remove the '#' from the hash
      const element = document.getElementById(id);
      if (element) {
        // Scroll to the element smoothly
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });

        // Set focus for accessibility
        element.setAttribute('tabindex', '-1'); // Make it focusable
        element.focus();
      }
    }
  }, [location]);

  return (
    <Layout>
      <div className="relative flex flex-col min-h-screen overflow-hidden font-sans bg-yellow-50">
        {/* Animated Bees */}
        <AnimatedBee />
        <AnimatedBee style={{ top: '20%', left: '10%' }} />
        <AnimatedBee style={{ top: '50%', left: '80%' }} />
        <AnimatedBee style={{ top: '70%', left: '30%' }} />

        {/* SEO Optimization */}
        <Helmet>
          <title>About Us | Quick Thinkers</title>
          <meta
            name="description"
            content="Learn more about Quick Thinkers, our mission, story, and the dedicated team behind our innovative educational solutions."
          />
          <meta
            name="keywords"
            content="Quick Thinkers, about us, mission, educational technology, team, company story"
          />
          <link rel="canonical" href="https://www.quickthinkers.com/about" />
        </Helmet>

        {/* Main Content */}
        <main className="z-10 flex-grow">
          <section className="relative px-6 py-12 bg-yellow-100">
            <div className="mx-auto max-w-7xl">
              <Link
                to="/"
                className="flex items-center mb-6 text-yellow-600 transition-colors duration-300 hover:text-yellow-800"
              >
                <ChevronLeft className="w-5 h-5 mr-2" />
                Back to Home
              </Link>

              {/* Mission Section */}
              <div id="mission" className="mb-16">
                <div className="mb-10 text-center">
                  <h2 className="mb-4 text-3xl font-bold text-yellow-900 md:text-4xl">{aboutData.mission.title}</h2>
                  <p className="text-lg text-gray-700 md:text-xl">{aboutData.mission.content}</p>
                </div>
              </div>

              {/* Our Story Section */}
              <div id="our-story" className="mb-16">
                <div className="mb-10 text-center">
                  <h2 className="mb-4 text-3xl font-bold text-yellow-900 md:text-4xl">{aboutData.story.title}</h2>
                  <p className="text-lg text-gray-700 md:text-xl">{aboutData.story.content}</p>
                </div>
              </div>

              {/* Team Section */}
              <div id="team" className="mb-16">
                <div className="mb-10 text-center">
                  <h2 className="mb-4 text-3xl font-bold text-yellow-900 md:text-4xl">{aboutData.team.title}</h2>
                  <p className="text-lg text-gray-700 md:text-xl">Meet the dedicated team behind Quick Thinkers.</p>
                </div>
                <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
                  {aboutData.team.members.map((member, index) => (
                    <motion.div
                      key={index}
                      className="flex flex-col items-center p-4 transition-shadow duration-300 bg-white rounded-lg shadow hover:shadow-xl"
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      viewport={{ once: true }}
                      transition={{ duration: 0.5, delay: index * 0.1 }}
                    >
                      <img
                        src={member.photo}
                        alt={`${member.name} - ${member.role}`}
                        className="object-cover w-24 h-24 mb-4 bg-gray-200 rounded-full"
                      />
                      <h3 className="text-xl font-semibold text-gray-800">{member.name}</h3>
                      <p className="text-sm text-gray-500">{member.role}</p>
                      <p className="mt-2 text-center text-gray-600">{member.bio}</p>
                    </motion.div>
                  ))}
                </div>
              </div>

              {/* Contact Section */}
              <div id="contact" className="mb-16">
                <div className="mb-10 text-center">
                  <h2 className="mb-4 text-3xl font-bold text-yellow-900 md:text-4xl">{aboutData.contact.title}</h2>
                  <p className="text-lg text-gray-700 md:text-xl">{aboutData.contact.content}</p>
                </div>
                <div className="flex flex-col items-center space-y-4">
                  <a
                    href={`mailto:${aboutData.contact.email}`}
                    className="flex items-center px-6 py-3 text-white transition-colors duration-300 bg-yellow-600 rounded-full hover:bg-yellow-500"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </Layout>
  );
};

export default About;
