// src/components/ui/Checkbox.js

import React from 'react';

const Checkbox = ({ checked, onChange, className = '', ...props }) => {
  return (
    <input
      type="checkbox"
      checked={checked}
      onChange={onChange}
      className={`form-checkbox h-5 w-5 text-blue-600 ${className}`}
      {...props}
    />
  );
};

export default Checkbox;
