/* src/components/Header.js */

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  ChevronDown,
  ChevronUp,
} from 'lucide-react';
import beetitle from '../assets/beetitle.svg'; // Adjust the path if necessary

const Header = () => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  const toggleDropdown = (menu) => {
    setOpenDropdown((prev) => (prev === menu ? null : menu));
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024); // Increased breakpoint for better layout
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.dropdown')) {
        setOpenDropdown(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="relative z-30 p-6 bg-white shadow-md animate-fadeIn">
      <div className="flex flex-col items-center lg:flex-row lg:justify-between">
        {/* Logo */}
        <div className="flex justify-center mb-4 lg:mb-0 lg:justify-start">
          <Link to="/">
            <img
              src={beetitle}
              alt="Quick Thinkers"
              className="object-contain transition-transform duration-500 transform h-28 hover:scale-105"
            />
          </Link>
        </div>

        {/* Navigation and Login Buttons */}
        <div className="flex flex-col items-center lg:flex-row lg:items-center lg:justify-center lg:space-x-6">
          <nav className="flex flex-col items-center space-y-4 lg:flex-row lg:space-y-0 lg:space-x-6">
            {/* Solutions Dropdown */}
            <div className="relative dropdown">
              <button
                onClick={() => toggleDropdown('solutions')}
                className="flex items-center justify-center w-full text-xl font-bold text-gray-800 transition-colors lg:justify-start hover:text-yellow-600 focus:outline-none"
                aria-haspopup="true"
                aria-expanded={openDropdown === 'solutions'}
              >
                Solutions
                {openDropdown === 'solutions' ? (
                  <ChevronUp className="w-4 h-4 ml-1 transition-transform duration-300 transform rotate-180" />
                ) : (
                  <ChevronDown className="w-4 h-4 ml-1 transition-transform duration-300" />
                )}
              </button>
              {openDropdown === 'solutions' && (
                <div className="absolute left-0 z-50 px-4 py-4 mt-2 bg-white rounded-lg shadow-lg lg:right-0 w-80 animate-slideDown">
                  <div className="px-4 py-2">
                    <Link
                      to="/solutions#educators"
                      className="block font-semibold text-gray-800 transition-colors duration-300 hover:text-yellow-600"
                      onClick={() => setOpenDropdown(null)}
                    >
                      Educators
                    </Link>
                    <p className="mt-1 text-gray-600">
                      Spend less time administrating and more time engaging students.
                    </p>
                  </div>
                  <div className="px-4 py-2">
                    <Link
                      to="/solutions#schools-districts"
                      className="block font-semibold text-gray-800 transition-colors duration-300 hover:text-yellow-600"
                      onClick={() => setOpenDropdown(null)}
                    >
                      Schools & Districts
                    </Link>
                    <p className="mt-1 text-gray-600">
                      Empower teachers with the tools and data to enrich the student experience.
                    </p>
                  </div>
                  <div className="px-4 py-2">
                    <Link
                      to="/solutions#tutors"
                      className="block font-semibold text-gray-800 transition-colors duration-300 hover:text-yellow-600"
                      onClick={() => setOpenDropdown(null)}
                    >
                      Tutors
                    </Link>
                    <p className="mt-1 text-gray-600">
                      Build better connections with students.
                    </p>
                  </div>
                  <div className="px-4 py-2">
                    <Link
                      to="/solutions#higher-ed"
                      className="block font-semibold text-gray-800 transition-colors duration-300 hover:text-yellow-600"
                      onClick={() => setOpenDropdown(null)}
                    >
                      Higher Ed
                    </Link>
                    <p className="mt-1 text-gray-600">
                      Supplement on-campus solutions with targeted academic support.
                    </p>
                  </div>
                </div>
              )}
            </div>

            {/* Why Quick Thinkers Dropdown */}
            <div className="relative dropdown">
              <button
                onClick={() => toggleDropdown('why')}
                className="flex items-center justify-center w-full text-xl font-bold text-gray-800 transition-colors lg:justify-start hover:text-yellow-600 focus:outline-none whitespace-nowrap"
                aria-haspopup="true"
                aria-expanded={openDropdown === 'why'}
              >
                Why Quick Thinkers
                {openDropdown === 'why' ? (
                  <ChevronUp className="flex-shrink-0 w-4 h-4 ml-1 transition-transform duration-300 transform rotate-180" />
                ) : (
                  <ChevronDown className="flex-shrink-0 w-4 h-4 ml-1 transition-transform duration-300" />
                )}
              </button>
              {openDropdown === 'why' && (
                <div className="absolute left-0 z-50 px-4 py-4 mt-2 bg-white rounded-lg shadow-lg lg:right-0 w-80 animate-slideDown">
                  <div className="px-4 py-2">
                    <Link
                      to="/why-quick-thinkers#customer-stories"
                      className="block font-semibold text-gray-800 transition-colors duration-300 hover:text-yellow-600"
                      onClick={() => setOpenDropdown(null)}
                    >
                      Customer Stories
                    </Link>
                    <p className="mt-1 text-gray-600">
                      Stories of school, district, and classroom success.
                    </p>
                  </div>
                  <div className="px-4 py-2">
                    <Link
                      to="/why-quick-thinkers#integration-partners"
                      className="block font-semibold text-gray-800 transition-colors duration-300 hover:text-yellow-600"
                      onClick={() => setOpenDropdown(null)}
                    >
                      Integration and Partners
                    </Link>
                    <p className="mt-1 text-gray-600">
                      The key connections that make our platform powerful.
                    </p>
                  </div>
                  <div className="px-4 py-2">
                    <Link
                      to="/why-quick-thinkers#efficacy"
                      className="block font-semibold text-gray-800 transition-colors duration-300 hover:text-yellow-600"
                      onClick={() => setOpenDropdown(null)}
                    >
                      Efficacy
                    </Link>
                    <p className="mt-1 text-gray-600">
                      The data and models behind our platform and tools.
                    </p>
                  </div>
                  <div className="px-4 py-2">
                    <Link
                      to="/why-quick-thinkers#privacy"
                      className="block font-semibold text-gray-800 transition-colors duration-300 hover:text-yellow-600"
                      onClick={() => setOpenDropdown(null)}
                    >
                      Privacy
                    </Link>
                    <p className="mt-1 text-gray-600">
                      How we safeguard personal and educational data.
                    </p>
                  </div>
                </div>
              )}
            </div>

            {/* Resources Dropdown */}
            <div className="relative dropdown">
              <button
                onClick={() => toggleDropdown('resources')}
                className="flex items-center justify-center w-full text-xl font-bold text-gray-800 transition-colors lg:justify-start hover:text-yellow-600 focus:outline-none"
                aria-haspopup="true"
                aria-expanded={openDropdown === 'resources'}
              >
                Resources
                {openDropdown === 'resources' ? (
                  <ChevronUp className="w-4 h-4 ml-1 transition-transform duration-300 transform rotate-180" />
                ) : (
                  <ChevronDown className="w-4 h-4 ml-1 transition-transform duration-300" />
                )}
              </button>
              {openDropdown === 'resources' && (
                <div className="absolute left-0 z-50 py-2 mt-2 bg-white rounded-lg shadow-lg lg:right-0 w-72 animate-slideDown">
                  <Link
                    to="/resources#blog"
                    className="block px-4 py-2 text-gray-700 transition-colors duration-300 hover:bg-yellow-100"
                    onClick={() => setOpenDropdown(null)}
                  >
                    Blog
                  </Link>
                  <Link
                    to="/resources#help-center"
                    className="block px-4 py-2 text-gray-700 transition-colors duration-300 hover:bg-yellow-100"
                    onClick={() => setOpenDropdown(null)}
                  >
                    Help Center
                  </Link>
                  <Link
                    to="/resources#webinars"
                    className="block px-4 py-2 text-gray-700 transition-colors duration-300 hover:bg-yellow-100"
                    onClick={() => setOpenDropdown(null)}
                  >
                    Webinars
                  </Link>
                </div>
              )}
            </div>

            {/* About Link */}
            <Link
              to="/about"
              className="flex justify-center w-full text-xl font-bold text-gray-800 transition-colors transition-transform duration-300 transform lg:justify-start hover:text-yellow-600 hover:scale-105 whitespace-nowrap"
            >
              About
            </Link>

            {/* Pricing without Dropdown */}
            <Link
              to="/pricing"
              className="flex justify-center w-full text-xl font-bold text-gray-800 transition-colors transition-transform duration-300 transform lg:justify-start hover:text-yellow-600 hover:scale-105 whitespace-nowrap"
            >
              Pricing
            </Link>
          </nav>

          {/* Login Buttons */}
          <div className="flex flex-col items-center w-full mt-4 space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4 lg:justify-center lg:w-auto lg:mt-0">
            <Link
              to="/login"
              className="px-6 py-3 text-center text-white transition-all duration-300 transform rounded-full shadow-md whitespace-nowrap bg-gradient-to-r from-yellow-400 to-yellow-500 hover:from-yellow-500 hover:to-yellow-600 hover:-translate-y-1 hover:scale-105"
            >
              Student Login
            </Link>
            <Link
              to="/teacherlogin"
              className="px-6 py-3 text-center text-white transition-all duration-300 transform rounded-full shadow-md whitespace-nowrap bg-gradient-to-r from-yellow-400 to-yellow-500 hover:from-yellow-500 hover:to-yellow-600 hover:-translate-y-1 hover:scale-105"
            >
              Teacher Login
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
