// src/contexts/AuthContext.js

import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import {
  onAuthStateChanged,
  setPersistence,
  browserLocalPersistence,
  signOut,
} from 'firebase/auth';
import { auth, db } from '../firebase';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { toast } from 'react-toastify'; // Optional: For better notifications
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for react-toastify

const AuthContext = createContext();

const INACTIVITY_TIMEOUT = 60 * 60 * 1000; // 60 minutes

/**
 * Custom hook to use the AuthContext
 */
export const useAuth = () => {
  return useContext(AuthContext);
};

/**
 * AuthProvider component to wrap around the application
 */
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);

  // Ref to store the inactivity timeout ID
  const inactivityTimeoutRef = useRef(null);

  // Ref to store the unsubscribe function from onAuthStateChanged
  const unsubscribeRef = useRef(null);

  /**
   * Function to handle sign-out due to inactivity
   */
  const handleSignOut = useCallback(() => {
    // Optional: Replace alert with a toast notification for better UX
    toast.info('You have been signed out due to inactivity.');

    signOut(auth)
      .then(() => {
        console.log('User signed out due to inactivity');
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  }, []);

  /**
   * Function to reset the inactivity timer
   */
  const resetInactivityTimeout = useCallback(() => {
    if (inactivityTimeoutRef.current) {
      clearTimeout(inactivityTimeoutRef.current);
    }
    inactivityTimeoutRef.current = setTimeout(handleSignOut, INACTIVITY_TIMEOUT);
  }, [handleSignOut]);

  /**
   * Function to initialize user activity listeners
   */
  const initializeActivityListeners = useCallback(() => {
    const events = ['mousemove', 'keydown', 'scroll', 'touchstart'];

    const eventHandler = () => {
      resetInactivityTimeout();
    };

    events.forEach((event) => {
      window.addEventListener(event, eventHandler);
    });

    // Initialize the timeout when listeners are set
    resetInactivityTimeout();

    // Return a cleanup function to remove event listeners and clear the timeout
    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, eventHandler);
      });
      if (inactivityTimeoutRef.current) {
        clearTimeout(inactivityTimeoutRef.current);
      }
    };
  }, [resetInactivityTimeout]);

  /**
   * Function to update user's online status
   */
  const updateUserOnline = useCallback(async (userDocRef) => {
    await setDoc(
      userDocRef,
      {
        isOnline: true,
        lastActive: serverTimestamp(),
      },
      { merge: true }
    );
  }, []);

  /**
   * useEffect to handle authentication state changes
   */
  useEffect(() => {
    const initAuth = async () => {
      try {
        await setPersistence(auth, browserLocalPersistence);

        // Assign the unsubscribe function to the ref
        unsubscribeRef.current = onAuthStateChanged(auth, async (user) => {
          setCurrentUser(user);
          if (user) {
            try {
              const userDocRef = doc(
                db,
                user.isAnonymous ? 'studentusers' : 'users',
                user.uid
              );
              const userDoc = await getDoc(userDocRef);

              if (userDoc.exists()) {
                const userData = userDoc.data();
                const role = userData.role || null;
                setUserRole(role);

                if (role === 'teacher') {
                  await updateUserOnline(userDocRef);
                }
              } else {
                console.error('User document does not exist');
                setUserRole(null);
              }
            } catch (error) {
              console.error('Error retrieving user role:', error);
              setUserRole(null);
            }
          } else {
            setUserRole(null);
          }
          setLoading(false);
        });
      } catch (error) {
        console.error('Error setting auth persistence:', error);
        setLoading(false);
      }
    };

    initAuth();

    // Cleanup function
    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
      }
      if (inactivityTimeoutRef.current) {
        clearTimeout(inactivityTimeoutRef.current);
      }
    };
  }, [updateUserOnline]);

  /**
   * useEffect to handle activity listeners based on user state
   */
  useEffect(() => {
    if (currentUser) {
      const cleanup = initializeActivityListeners();
      return cleanup; // Cleanup when user logs out or component unmounts
    }
    // If user logs out, ensure the timeout is cleared
    if (inactivityTimeoutRef.current) {
      clearTimeout(inactivityTimeoutRef.current);
    }
  }, [currentUser, initializeActivityListeners]);

  /**
   * Memoize the context value to optimize performance
   */
  const value = useMemo(
    () => ({
      currentUser,
      userRole,
    }),
    [currentUser, userRole]
  );

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
