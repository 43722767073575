import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  LogOut,
  X,
  Pencil,
  Airplay,
  Book,
  Calculator,
  Clipboard,
  BookOpen,
  User,
} from 'lucide-react';
import { signOut } from 'firebase/auth';
import { auth, db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import {
  doc,
  getDoc,
  onSnapshot,
  runTransaction,
  deleteDoc,
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/beetitle.svg';
import '../../styles/StudentHomepage.css';

// Import the tool components
import Slideshow from './slideshow';
import DigitalWorkbook from './digitalworkbook';
import StudentWhiteboard from './studentwhiteboard';
import BookQuest from './bookquest';
import Games from './games';
import Assessment from './assessment';
import Character from './character';

const StudentHomepage = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [studentData, setStudentData] = useState(null);
  const [classData, setClassData] = useState(null);
  const [activeAppComponent, setActiveAppComponent] = useState(null);
  const [currentApp, setCurrentApp] = useState(null);

  const hasLoggedOutRef = useRef(false);

  const handleLogout = useCallback(async () => {
    if (hasLoggedOutRef.current) return;
    hasLoggedOutRef.current = true;

    console.log('handleLogout triggered.');

    if (!currentUser) {
      console.log('No authenticated user. Redirecting to home.');
      navigate('/');
      return;
    }

    const studentUserRef = doc(db, 'studentusers', currentUser.uid);
    let studentDataDoc = null;

    try {
      const studentSnap = await getDoc(studentUserRef);
      studentDataDoc = studentSnap.exists() ? studentSnap.data() : null;
    } catch (error) {
      console.error('Error fetching student user document:', error);
    }

    if (!studentDataDoc) {
      console.log('No student data found. Signing out.');
      try {
        await signOut(auth);
        navigate('/');
      } catch (error) {
        console.error('Error signing out:', error);
      }
      return;
    }

    const { classId, name } = studentDataDoc;

    if (classId && name) {
      try {
        await runTransaction(db, async (transaction) => {
          const activeClassRef = doc(db, 'activeclasses', classId);
          const activeClassDoc = await transaction.get(activeClassRef);
          if (!activeClassDoc.exists()) {
            console.log('Active class document does not exist. Possibly already deactivated.');
            return;
          }

          const currentSignedIn = activeClassDoc.data().signinstudents || [];

          transaction.update(activeClassRef, {
            signinstudents: currentSignedIn.filter((student) => student !== name),
          });
          console.log(`Removed ${name} from activeclasses/${classId}.`);
        });

        await deleteDoc(studentUserRef);
        console.log(`Successfully deleted ${name}'s document from studentusers.`);
      } catch (error) {
        console.error('Error during sign-out process:', error);
      }
    }

    try {
      await signOut(auth);
      console.log('User signed out successfully.');
    } catch (error) {
      console.error('Error signing out:', error);
    }

    navigate('/');
  }, [currentUser, navigate]);

  useEffect(() => {
    const fetchStudentData = async () => {
      if (!currentUser) {
        setLoading(false);
        navigate('/');
        return;
      }

      try {
        const studentUserRef = doc(db, 'studentusers', currentUser.uid);
        const studentSnap = await getDoc(studentUserRef);

        if (studentSnap.exists()) {
          const data = studentSnap.data();
          console.log('Fetched student data:', data);

          if (!data.name || !data.classId) {
            console.warn('Student name or classId is missing in studentusers/{uid} document.');
            await handleLogout();
            return;
          }

          setStudentData(data);
        } else {
          console.warn('No student user data found. Logging out student.');
          await handleLogout();
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStudentData();
  }, [currentUser, navigate, handleLogout]);

  useEffect(() => {
    if (!studentData || !studentData.classId) return;

    console.log(`Setting up listener for activeclasses/${studentData.classId}`);

    const classDocRef = doc(db, 'activeclasses', studentData.classId);

    const unsubscribe = onSnapshot(
      classDocRef,
      (docSnap) => {
        if (docSnap.exists()) {
          console.log('Class document exists.');
          const data = docSnap.data();
          setClassData(data);
        } else {
          console.log('Class document does not exist (deleted).');
          handleLogout();
        }
      },
      (error) => {
        console.error('Error in onSnapshot:', error);
      }
    );

    return () => unsubscribe();
  }, [studentData, handleLogout]);

  const allTools = [
    { name: 'Whiteboard', description: 'Collaborative drawing and typing', icon: 'Pencil' },
    { name: 'Slideshow', description: 'View teacher presentations', icon: 'Airplay' },
    { name: 'Digital Workbook', description: 'Interactive workbook for students', icon: 'Book' },
    { name: 'Assessment', description: 'Student Assessments and Feedback', icon: 'Clipboard' },
    { name: 'Book Quest', description: 'Interactive Book Quest Adventures', icon: 'BookOpen' },
    { name: 'Games', description: 'Educational Games for Learning', icon: 'Airplay' },
    { name: 'Character', description: 'Learn about character traits', icon: 'User' },
  ];

  const getToolStatus = (toolName) => {
    if (!classData) return { active: false, reason: 'No class data' };

    if (classData.mode === 'locked') {
      if (classData.currentApp === toolName) {
        return { active: true, reason: 'Locked mode: Current App' };
      } else {
        return { active: false, reason: 'Locked' };
      }
    } else if (classData.mode === 'unlocked') {
      if (classData.accessibleModules && classData.accessibleModules.includes(toolName)) {
        return { active: true, reason: 'Unlocked mode: Accessible' };
      } else {
        return { active: false, reason: 'Unlocked mode: Not accessible' };
      }
    } else {
      return { active: false, reason: 'Unknown mode' };
    }
  };

  const handleToolClick = useCallback(
    (appName, isActive) => {
      if (!isActive) return;

      const appComponents = {
        'Digital Workbook': DigitalWorkbook,
        Slideshow: Slideshow,
        Whiteboard: StudentWhiteboard,
        'Book Quest': BookQuest,
        Games: Games,
        Assessment: Assessment,
        Character: Character,
      };

      if (appComponents.hasOwnProperty(appName)) {
        const AppComponent = appComponents[appName];
        setCurrentApp(appName);
        setActiveAppComponent(
          <AppComponent
            onClose={() => {
              setActiveAppComponent(null);
              setCurrentApp(null);
            }}
            classID={studentData?.classId}
            studentName={studentData?.name}
          />
        );
      } else {
        console.warn(`No component found for app: ${appName}`);
        setActiveAppComponent(null);
      }
    },
    [studentData]
  );

  if (loading || !classData) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-blue-50 to-green-50">
        <div className="text-xl">Loading...</div>
      </div>
    );
  }

  if (!studentData) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-blue-50 to-green-50">
        <div className="text-xl text-red-500">No student data found. Please log in again.</div>
      </div>
    );
  }

  return (
    <div className="max-w-full min-h-screen p-6 bg-gradient-to-br from-blue-50 to-green-50">
      <header className="flex flex-col items-center mb-12">
        <div className="flex items-center justify-center mb-6">
          <img src={logo} alt="Logo" className="h-64 w-140" />
        </div>
        <div className="px-16 py-4 text-blue-900 bg-transparent rounded-lg">
          <h1 className="text-4xl font-bold">Welcome, {studentData.name}!</h1>
        </div>
        <div className="mt-4">
          <button
            onClick={handleLogout}
            className="flex items-center px-4 py-2 space-x-2 text-white bg-blue-500 rounded-full hover:bg-blue-600"
          >
            <LogOut size={20} />
            <span>Sign Out</span>
          </button>
        </div>
      </header>

      <main>
        <h2 className="mb-4 text-2xl font-semibold text-center">Your Apps</h2>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4">
          {allTools.map((tool) => {
            const { active, reason } = getToolStatus(tool.name);
            const IconComponent = {
              Pencil, Airplay, Book, Calculator, Clipboard, BookOpen, User
            }[tool.icon] || Pencil;

            return (
              <div
                key={tool.name}
                className={`flex flex-col items-center justify-center h-48 p-6 rounded-lg shadow-lg transition-transform transform ${
                  active
                    ? 'bg-white hover:bg-gray-100 cursor-pointer hover:scale-105'
                    : 'bg-gray-300 cursor-not-allowed'
                }`}
                onClick={() => handleToolClick(tool.name, active)}
                title={active ? `${tool.name}` : reason}
              >
                <div className={`mb-4 ${active ? 'text-blue-500' : 'text-gray-500'}`}>
                  <IconComponent size={40} />
                </div>
                <h3 className={`mb-2 text-xl font-semibold ${active ? 'text-blue-900' : 'text-gray-600'}`}>
                  {tool.name}
                </h3>
                <p className={`text-center ${active ? 'text-gray-600' : 'text-gray-500'}`}>
                  {active
                    ? tool.description
                    : classData.mode === 'locked'
                    ? 'Locked'
                    : 'Module not accessible'}
                </p>
              </div>
            );
          })}
        </div>
      </main>

      <footer className="mt-16 text-center text-gray-600">
        <p>&copy; 2024 Learning Resources. All rights reserved.</p>
      </footer>

      {activeAppComponent && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div
            className={`relative ${
              currentApp === 'Whiteboard'
                ? 'w-[90vw] h-[90vh] p-0 bg-white rounded-lg shadow-xl overflow-hidden'
                : 'w-full max-w-3xl p-6 bg-white rounded-lg shadow-lg'
            }`}
          >
            <button
              onClick={() => {
                setActiveAppComponent(null);
                setCurrentApp(null);
              }}
              className="absolute z-10 text-2xl text-gray-600 top-4 right-4 hover:text-gray-800 focus:outline-none"
              aria-label="Close Module"
            >
              <X size={24} />
            </button>

            {currentApp !== 'Whiteboard' && (
              <h2 className="mb-4 text-2xl font-semibold text-center">{currentApp}</h2>
            )}

            <div
              className={`${
                currentApp === 'Whiteboard' ? 'w-full h-full' : 'w-full'
              }`}
            >
              {activeAppComponent}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(StudentHomepage);