// src/components/Students/DigitalWorkbook.js

import React, { useState, useEffect } from 'react';
import { FaSave, FaEye } from 'react-icons/fa';
import { db } from '../../firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';
import PropTypes from 'prop-types';

const DigitalWorkbook = ({ onClose, classID, studentName }) => {
  const { currentUser } = useAuth();
  const [workbookContent, setWorkbookContent] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [saveMessage, setSaveMessage] = useState('');
  
  // States for title and instructions
  const [title, setTitle] = useState('');
  const [instructions, setInstructions] = useState('');
  
  // States for example workbook
  const [exampleContent, setExampleContent] = useState('');
  const [loadingExample, setLoadingExample] = useState(false);
  const [errorExample, setErrorExample] = useState(null);

  // State for example popup
  const [showExample, setShowExample] = useState(false);
  
  // Load existing workbook content and title/instructions from Firestore on mount
  useEffect(() => {
    const fetchWorkbook = async () => {
      if (!classID) {
        console.error('classID is undefined. Cannot fetch workbook.');
        return;
      }

      if (!currentUser?.uid) {
        console.error('currentUser.uid is undefined. Cannot fetch workbook.');
        return;
      }

      try {
        // Fetch the student's own workbook
        const workbookDocRef = doc(db, 'activeclasses', classID, 'workbook', currentUser.uid);
        const docSnap = await getDoc(workbookDocRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.workbook) {
            setWorkbookContent(data.workbook);
          }
          if (data.title) {
            setTitle(data.title);
          }
          if (data.instructions) {
            setInstructions(data.instructions);
          }
        }

        // Fetch example workbook
        fetchExampleWorkbook();
      } catch (error) {
        console.error('Error fetching workbook:', error);
      }
    };

    const fetchExampleWorkbook = async () => {
      if (!classID) {
        console.error('classID is undefined. Cannot fetch example workbook.');
        return;
      }

      setLoadingExample(true);
      setErrorExample(null);

      try {
        const exampleDocRef = doc(db, 'activeclasses', classID, 'workbook', 'work-example');
        const exampleSnap = await getDoc(exampleDocRef);
        if (exampleSnap.exists()) {
          const data = exampleSnap.data();
          setExampleContent(data.workbook || '');
        } else {
          setExampleContent('');
        }
      } catch (err) {
        console.error('Error fetching example workbook:', err);
        setErrorExample('Failed to load example workbook.');
      } finally {
        setLoadingExample(false);
      }
    };

    fetchWorkbook();
  }, [classID, currentUser?.uid]);

  // Function to handle saving workbook to Firestore
  const handleSave = async () => {
    if (!currentUser) {
      alert('You must be logged in to save your workbook.');
      return;
    }

    setIsSaving(true);
    setSaveMessage('');

    try {
      const workbookDocRef = doc(db, 'activeclasses', classID, 'workbook', currentUser.uid);
      const workbookData = {
        name: studentName,
        workbook: workbookContent,
        updatedAt: new Date(),
      };

      await setDoc(workbookDocRef, workbookData, { merge: true });
      setSaveMessage('Workbook saved successfully!');
    } catch (error) {
      console.error('Error saving workbook:', error);
      setSaveMessage('Failed to save workbook. Please try again.');
    } finally {
      setIsSaving(false);
      setTimeout(() => setSaveMessage(''), 3000);
    }
  };

  // Handle example popup
  const handleViewExample = () => {
    setShowExample(true);
  };

  const handleCloseExample = () => {
    setShowExample(false);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-50">
      <div className="relative w-full max-w-3xl p-6 overflow-y-auto bg-white rounded-lg shadow-lg">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute text-2xl text-gray-500 top-4 right-4 hover:text-gray-700"
          aria-label="Close"
        >
          &times;
        </button>

        {/* Header */}
        <div className="mb-6 text-center">
          <h2 className="text-2xl font-semibold">Digital Workbook</h2>
          <p className="text-gray-600">Your name: {studentName}</p>
        </div>

        {/* Title and Instructions */}
        {title && (
          <div className="mb-4">
            <h3 className="text-xl font-semibold">{title}</h3>
            <p className="text-gray-700">{instructions}</p>
          </div>
        )}

        {/* Save and View Example Buttons */}
        <div className="flex justify-between mb-4">
          <button
            onClick={handleSave}
            className={`flex items-center px-4 py-2 text-white bg-green-500 rounded hover:bg-green-600 ${
              isSaving ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            title="Save Workbook"
            disabled={isSaving}
          >
            <FaSave className="mr-2" />
            {isSaving ? 'Saving...' : 'Save'}
          </button>
          
          <button
            onClick={handleViewExample}
            className="flex items-center px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
            title="View Example"
          >
            <FaEye className="mr-2" />
            View Example
          </button>
        </div>

        {/* Save Feedback Message */}
        {saveMessage && (
          <div className="mb-4 text-sm text-center text-green-600">
            {saveMessage}
          </div>
        )}

        {/* Workbook Textarea */}
        <div className="mb-8">
          <h3 className="mb-4 text-xl font-semibold">Your Workbook</h3>

          <textarea
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500 min-h-[300px] bg-gray-50"
            placeholder="Write your workbook content here..."
            value={workbookContent}
            onChange={(e) => setWorkbookContent(e.target.value)}
          ></textarea>
        </div>

        {/* Example Popup */}
        {showExample && (
          <div className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-50 z-60">
            <div className="relative w-full max-w-2xl p-6 bg-white rounded-lg shadow-lg">
              {/* Close Button */}
              <button
                onClick={handleCloseExample}
                className="absolute text-2xl text-gray-500 top-4 right-4 hover:text-gray-700"
                aria-label="Close"
              >
                &times;
              </button>

              {/* Example Content */}
              <div>
                <h3 className="mb-4 text-xl font-semibold">Example Workbook</h3>
                
                {loadingExample && <p>Loading example workbook...</p>}
                {errorExample && <p className="text-red-500">{errorExample}</p>}
                
                {!loadingExample && !errorExample && (
                  <textarea
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 min-h-[300px] bg-gray-50"
                    value={exampleContent}
                    readOnly
                  ></textarea>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// Add PropTypes for validation
DigitalWorkbook.propTypes = {
  onClose: PropTypes.func.isRequired,
  classID: PropTypes.string.isRequired,
  studentName: PropTypes.string.isRequired,
};

export default DigitalWorkbook;
