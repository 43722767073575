import React, { useEffect, useRef, useState, useCallback } from 'react';
import { db } from '../../firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { Stage, Layer, Line, Rect } from 'react-konva';

const StudentWhiteboard = ({ onClose, classID }) => {
  const [loading, setLoading] = useState(true);
  const [lines, setLines] = useState([]);
  const [backgroundSettings, setBackgroundSettings] = useState({
    type: 'blank',
    color: '#FFFFFF',
    lineColor: '#808080',
    spacing: 40,
    verticalLines: 1,
    horizontalLines: 1,
    verticalLineThickness: 5,
    horizontalLineThickness: 5,
  });
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  // Original canvas dimensions
  const ORIGINAL_WIDTH = 1600;
  const ORIGINAL_HEIGHT = 900;

  const updateDimensions = useCallback(() => {
    if (containerRef.current) {
      const { clientWidth, clientHeight } = containerRef.current;
      setDimensions({ width: clientWidth, height: clientHeight });
    }
  }, []);

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, [updateDimensions]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (!classID) {
      console.error('classID is undefined. Please provide a valid classID.');
      setLoading(false);
      return;
    }

    const whiteboardDocRef = doc(db, 'activeclasses', classID, 'whiteboard', 'data');

    const unsubscribe = onSnapshot(
      whiteboardDocRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setLines(data.drawingData || []);
          setBackgroundSettings(data.backgroundSettings || backgroundSettings);
        } else {
          console.warn('No whiteboard data found for the provided classID.');
        }
        setLoading(false);
      },
      (error) => {
        console.error('Error fetching whiteboard data:', error);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [classID, backgroundSettings]);

  const renderBackground = () => {
    const {
      type,
      color,
      lineColor,
      spacing,
      verticalLines,
      horizontalLines,
      verticalLineThickness,
      horizontalLineThickness,
    } = backgroundSettings;
    const stageWidth = ORIGINAL_WIDTH;
    const stageHeight = ORIGINAL_HEIGHT;
    const linesArray = [];

    if (type === 'lines' || type === 'grid') {
      for (let y = spacing; y < stageHeight; y += spacing) {
        linesArray.push(
          <Line
            key={`h-${y}`}
            points={[0, y, stageWidth, y]}
            stroke={lineColor}
            strokeWidth={spacing / 10}
          />
        );
      }

      if (type === 'grid') {
        for (let x = spacing; x < stageWidth; x += spacing) {
          linesArray.push(
            <Line
              key={`v-${x}`}
              points={[x, 0, x, stageHeight]}
              stroke={lineColor}
              strokeWidth={spacing / 10}
            />
          );
        }
      }
    }

    if (type === 'cross') {
      const vSpacing = stageWidth / (verticalLines + 1);
      for (let i = 1; i <= verticalLines; i++) {
        linesArray.push(
          <Line
            key={`v-${i}`}
            points={[vSpacing * i, 0, vSpacing * i, stageHeight]}
            stroke={lineColor}
            strokeWidth={verticalLineThickness}
          />
        );
      }

      const hSpacing = stageHeight / (horizontalLines + 1);
      for (let i = 1; i <= horizontalLines; i++) {
        linesArray.push(
          <Line
            key={`h-${i}`}
            points={[0, hSpacing * i, stageWidth, hSpacing * i]}
            stroke={lineColor}
            strokeWidth={horizontalLineThickness}
          />
        );
      }
    }

    return (
      <Layer>
        <Rect x={0} y={0} width={stageWidth} height={stageHeight} fill={color} />
        {linesArray}
      </Layer>
    );
  };

  // Calculate scale factor and scaled dimensions
  const getScale = () => {
    const { width: containerWidth, height: containerHeight } = dimensions;
    if (ORIGINAL_WIDTH === 0 || ORIGINAL_HEIGHT === 0) {
      return 1;
    }

    const scaleX = containerWidth / ORIGINAL_WIDTH;
    const scaleY = containerHeight / ORIGINAL_HEIGHT;
    return Math.min(scaleX, scaleY);
  };

  const scale = getScale();
  const scaledWidth = ORIGINAL_WIDTH * scale;
  const scaledHeight = ORIGINAL_HEIGHT * scale;

  return (
    <div className="flex flex-col w-full h-full bg-gray-500"> {/* Grey background for borders */}
      {/* Updated Header */}
      <div className="relative flex items-center justify-center p-4 bg-gray-100">
        {/* Date and Time aligned to the left */}
        <div className="absolute text-gray-600 left-4">
          <div>{currentDateTime.toLocaleDateString()}</div>
          <div>{currentDateTime.toLocaleTimeString()}</div>
        </div>
        {/* Centered Title */}
        <h2 className="text-2xl font-bold">Whiteboard</h2>
      </div>
      
      <div 
        ref={containerRef}
        className="relative flex items-center justify-center flex-grow"
        style={{ backgroundColor: '#ffffff' }}
      >
        {loading ? (
          <div className="absolute inset-0 flex items-center justify-center">
            <span className="text-gray-500">Loading Whiteboard...</span>
          </div>
        ) : (
          // Wrapper div to control the size and centering of the Stage
          <div
            style={{
              width: scaledWidth,
              height: scaledHeight,
            }}
          >
            <Stage
              width={ORIGINAL_WIDTH}
              height={ORIGINAL_HEIGHT}
              scale={{ x: scale, y: scale }}
              style={{ display: 'block', backgroundColor: '#ffffff' }}
            >
              {renderBackground()}

              <Layer>
                {lines.map((line, i) => (
                  <Line
                    key={i}
                    points={line.points}
                    stroke={line.stroke}
                    strokeWidth={line.strokeWidth}
                    tension={0.5}
                    lineCap="round"
                    globalCompositeOperation={line.globalCompositeOperation}
                    opacity={line.opacity}
                  />
                ))}
              </Layer>
            </Stage>
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentWhiteboard;
