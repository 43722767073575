import React from 'react';
import { Link } from 'react-router-dom';
import {
  Bell,
  Book,
  Rocket,
  Users,
  BarChart,
  Lock,
  CheckCircle,
} from 'lucide-react';
import { Helmet } from 'react-helmet';

// Import Swiper components and styles
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';

// Import Assets
import heroImage from '../assets/heroimage.png';
import placeholder1 from '../assets/placeholder1.jpg';
import placeholder2 from '../assets/placeholder2.jpg';
import placeholder3 from '../assets/placeholder3.jpg';
import placeholder4 from '../assets/placeholder4.jpg';
import placeholder5 from '../assets/placeholder5.jpg';
import placeholder6 from '../assets/placeholder6.jpg';
import placeholder7 from '../assets/placeholder7.jpg';
import placeholder8 from '../assets/placeholder8.jpg';
import placeholder9 from '../assets/placeholder9.jpg';
import placeholder10 from '../assets/placeholder10.jpg';
import placeholder11 from '../assets/placeholder11.jpg';
import placeholder12 from '../assets/placeholder12.jpg';
import Bee from './Bee';

// Import CSS
import './QuickThinkersLandingPage.css';

// Import Layout
import Layout from './Layout';

// =========================
// Reusable Components
// =========================

// FeatureItem Component with Animation
const FeatureItem = ({ text }) => (
  <div className="flex items-center p-4 transition-transform duration-300 transform bg-white rounded-lg shadow hover:translate-x-2 animate-fadeIn">
    <CheckCircle className="w-6 h-6 text-green-500 animate-pulse" />
    <span className="ml-3 text-gray-700">{text}</span>
  </div>
);

// FeatureCard Component with Animation
const FeatureCard = ({ icon, title, description }) => (
  <div className="p-8 text-center transition-transform duration-300 transform bg-white rounded-lg shadow-lg hover:scale-105">
    <div className="flex justify-center mb-6">{icon}</div>
    <h3 className="mb-4 text-2xl font-bold text-yellow-900 animate-fadeIn">{title}</h3>
    <p className="text-gray-600 delay-200 animate-fadeIn">{description}</p>
  </div>
);

// SolutionButton Component with Animation
const SolutionButton = ({ icon, text }) => (
  <Link
    to={`/solutions/${text.toLowerCase().replace(/\s+/g, '-')}`}
    className="flex items-center justify-center p-6 text-yellow-600 transition-all transition-transform duration-300 transform bg-white rounded-lg shadow-md hover:bg-yellow-600 hover:text-white hover:scale-105 animate-fadeIn"
  >
    {icon}
    <span className="ml-3 font-semibold">{text}</span>
  </Link>
);

// =========================
// Main Component
// =========================

const QuickThinkersLandingPage = () => {
  // Array of imported carousel images
  const carouselImages = [
    placeholder1, placeholder2, placeholder10, placeholder3, placeholder4,
    placeholder5, placeholder12, placeholder6, placeholder7, placeholder8,
    placeholder9, placeholder11, placeholder1, placeholder2, placeholder10,
    placeholder3, placeholder4, placeholder5, placeholder12, placeholder6,
    placeholder7, placeholder8, placeholder9, placeholder11,
  ];

  // Define the number of bees
  const numberOfBees = 30;

  return (
    <Layout>
      {/* SEO Optimization */}
      <Helmet>
        <title>Home | Quick Thinkers</title>
        <meta
          name="description"
          content="Quick Thinkers offers interactive and immersive teaching systems to enhance student engagement and make learning fun. Discover our tools designed for educators."
        />
        <meta
          name="keywords"
          content="Quick Thinkers, education tools, student engagement, teaching systems, interactive learning, personalized learning"
        />
        <link rel="canonical" href="https://www.quickthinkers.com/" />
      </Helmet>

      {/* Hero Section with added padding */}
      <section className="relative px-4 py-10 overflow-hidden text-yellow-900 bg-yellow-100 lg:px-24 animate-fadeIn">
        <div className="flex flex-col-reverse items-center justify-between w-full lg:flex-row">
          {/* Text Content */}
          <div className="w-full pr-0 mb-12 lg:w-1/2 lg:pr-12 lg:mb-0">
            <h1 className="mt-12 mb-6 text-5xl font-bold leading-tight text-center animate-slideInLeft">
              Speed up learning with <br /> Quick Thinkers
            </h1>
            <p className="mb-8 text-xl text-center text-yellow-700 delay-200 animate-fadeIn">
              Engage students in their learning with our interactive and immersive teaching system. Designed to be quick and easy to use, you can start teaching any topic in a matter of minutes.
            </p>
            <Link
              to="/why-quick-thinkers/customer-stories"
              className="flex items-center justify-center px-8 py-3 text-xl font-semibold text-black transition-colors transition-transform duration-300 transform bg-white border-4 border-yellow-300 rounded-full shadow hover:bg-yellow-100 hover:scale-105 animate-slideInRight"
            >
              Explore Quick Thinkers 
            </Link>
          </div>

          {/* Updated Image Content with further reduced maximum size */}
          <div className="hidden w-full max-w-xl px-8 lg:block lg:w-1/3 lg:px-0">
            <img
              src={heroImage}
              alt="Hero"
              className="object-contain w-full h-full rounded-lg animate-fadeIn"
              style={{ objectPosition: 'middle' }}
            />
          </div>
        </div>

        {/* Animated Bees SVGs */}
        {Array.from({ length: numberOfBees }, (_, i) => (
          <Bee key={`bee-${i}`} id={i} />
        ))}
      </section>

      {/* Swiper Image Carousel */}
      <div className="px-4 my-20">
        <Swiper
          spaceBetween={12}
          slidesPerView="auto"
          loop={true}
          speed={12000}
          autoplay={{
            delay: 0,
            disableOnInteraction: false,
            pauseOnMouseEnter: false,
            reverseDirection: false,
          }}
          freeMode={{
            enabled: true,
            momentum: true,
            momentumRatio: 0.5,
            momentumVelocityRatio: 0.5,
          }}
          modules={[Autoplay, FreeMode]}
          className="mySwiper"
        >
          {carouselImages.map((image, index) => (
            <SwiperSlide key={`swiper-slide-${index}`} style={{ width: '400px', height: '400px' }}>
              <div className="w-full h-full overflow-hidden rounded-lg">
                <img
                  src={image}
                  alt={`Carousel Slide ${index + 1}`}
                  className="object-cover w-full h-full"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* Features Section with animations */}
      <section className="px-24 py-20 bg-white">
        <h2 className="mb-16 text-4xl font-bold text-center text-yellow-900 animate-fadeIn">
          Tools Designed with Educators
        </h2>
        <div className="grid max-w-6xl grid-cols-1 gap-12 mx-auto md:grid-cols-3">
          <FeatureCard
            icon={<Rocket className="w-16 h-16 text-yellow-600 animate-bounce" />}
            title="Student Engagement"
            description="Boost participation with our interactive and immersive lessons."
          />
          <FeatureCard
            icon={<Bell className="w-16 h-16 text-yellow-600 delay-200 animate-bounce" />}
            title="Real-time Feedback"
            description="Enhance learning with instant, personalized feedback systems."
          />
          <FeatureCard
            icon={<Book className="w-16 h-16 text-yellow-600 animate-bounce delay-400" />}
            title="Personalized Learning"
            description="Tailor instruction to individual student needs and learning styles."
          />
        </div>
      </section>

      {/* Impact Section with animations */}
      <section className="px-5 py-10 text-gray-800 bg-blue-100 md:px-24 md:py-20">
        <div className="max-w-6xl mx-auto">
          <h2 className="mb-12 text-3xl font-bold text-center md:text-4xl animate-fadeIn">
            The Impact of Quick Thinkers
          </h2>
          <div className="flex flex-col justify-around space-y-8 text-center md:flex-row md:space-y-0">
            <div className="animate-fadeIn">
              <div className="mb-2 text-4xl font-bold md:text-6xl">1M</div>
              <div className="text-lg md:text-xl">bees saved</div>
            </div>
            <div className="delay-200 animate-fadeIn">
              <div className="mb-2 text-4xl font-bold md:text-6xl">95.8%</div>
              <div className="text-lg md:text-xl">Satisfied teachers</div>
            </div>
            <div className="animate-fadeIn delay-400">
              <div className="mb-2 text-4xl font-bold md:text-6xl">Hours</div>
              <div className="text-lg md:text-xl">of teacher time saved</div>
            </div>
          </div>
        </div>
      </section>

      {/* Solutions Section with animations */}
      <section className="px-24 py-20 bg-yellow-50">
        <h2 className="mb-16 text-4xl font-bold text-center text-yellow-900 animate-fadeIn">
          For every student, every day
        </h2>
        <div className="grid max-w-6xl grid-cols-2 gap-8 mx-auto md:grid-cols-4">
          <SolutionButton icon={<Users />} text="Student Engagement" />
          <SolutionButton icon={<Rocket />} text="Gamified Collaboration" />
          <SolutionButton icon={<Bell />} text="Real-time Feedback" />
          <SolutionButton icon={<Book />} text="Differentiated Instruction" />
          <SolutionButton icon={<BarChart />} text="Assessments & Prep" />
          <SolutionButton icon={<Users />} text="1:1 Support" />
          <SolutionButton icon={<Lock />} text="Data Security" />
          <SolutionButton icon={<Book />} text="Aligned Content" />
        </div>
      </section>
    </Layout>
  );
};

export default QuickThinkersLandingPage;
